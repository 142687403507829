import { createRoute } from 'atomic-router'

export type CourseParams = { courseSlug: string }
export type LessonParams = { lessonSlug: string }

export const homeRoute = createRoute()
export const coursesRoute = createRoute()
export const courseRoute = createRoute<CourseParams>()
export const lessonRoute = createRoute<LessonParams>()
export const profileRoute = createRoute()
export const analyticsRoute = createRoute()
export const privacyPolicyRoute = createRoute()
