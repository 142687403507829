import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

export default function CourseAbstractLoader() {
  const theme = useUnit($theme)

  return (
    <>
      {/* Big image with title */}
      <ContentLoader
        className="rounded-t-lg bg-white bg-gradient-to-br from-oiqLightBlue-200 to-[#F6F9FC] dark:from-[#202020] dark:to-[#454545]"
        speed={2}
        viewBox="0 0 1785 886"
        backgroundColor={theme === 'dark' ? '#202020' : '#C6D2DD'}
        foregroundColor={theme === 'dark' ? '#5E5E5E' : '#F6F9FC'}
      >
        <rect x="652" y="640" rx="3" ry="3" width="476" height="42" />
        <rect x="192" y="691" rx="3" ry="3" width="1400" height="140" />
      </ContentLoader>

      {/* Abstract */}
      <ContentLoader
        className="rounded-b-lg bg-white dark:bg-oiqLightGreen-400"
        speed={2}
        viewBox="0 0 1785 656"
        backgroundColor={theme === 'dark' ? '#5C5C4B' : '#C6D2DD'}
        foregroundColor={theme === 'dark' ? '#88886d' : '#F6F9FC'}
      >
        <rect x="200" y="70" rx="10" ry="10" width="100" height="21" />
        <rect x="200" y="125" rx="10" ry="10" width="850" height="178" />
        <rect x="200" y="373" rx="10" ry="10" width="100" height="21" />
        <rect x="200" y="430" rx="10" ry="10" width="800" height="120" />
        <circle cx="1290" cy="170" r="55" />
        <rect x="1355" y="145" rx="10" ry="10" width="120" height="21" />
        <rect x="1355" y="175" rx="10" ry="10" width="80" height="30" />
        <rect x="1235" y="250" rx="0" ry="0" width="300" height="1" />
        <rect x="1235" y="280" rx="17" ry="17" width="140" height="35" />
        <rect x="1380" y="280" rx="17" ry="17" width="140" height="35" />
        <rect x="1235" y="320" rx="17" ry="17" width="140" height="35" />
        <rect x="1235" y="485" rx="0" ry="0" width="300" height="1" />
        <rect x="1240" y="500" rx="10" ry="10" width="100" height="48" />
        <rect x="1345" y="508" rx="10" ry="10" width="120" height="40" />
      </ContentLoader>
    </>
  )
}
