import { Link } from 'atomic-router-react'
import dp from 'dompurify'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { CourseCard as Card } from 'shared/gql'
import { courseRoute } from 'shared/routes'
import { $theme } from 'shared/theme'
import { Icon } from 'shared/ui/icon'

type Props = {
  card: Card
}

export default function CourseCard({ card }: Props) {
  const { t } = useTranslation()
  const theme = useUnit($theme)

  const iconColor = theme === 'light' ? '#171717' : '#D6D6BC'

  return (
    <Link
      className="flex flex-col rounded-md border-2 border-transparent bg-oiqWhite-50 font-antonymMedium duration-200 hover:border-oiqDarkGray-800 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 dark:bg-oiqDarkGray-800 dark:hover:border-oiqDarkGray-500"
      to={courseRoute}
      params={{ courseSlug: card.slug }}
    >
      <div className="flex grow flex-col pb-6 pt-2">
        <div className="mb-2 flex flex-row items-center justify-between pl-2 pr-4 sm:mb-4">
          <div className="flex flex-row items-center">
            <Icon
              name="entityCourse/course_icon"
              color={iconColor}
              className="h-auto w-6"
            />
            <span className="pl-1 text-lg">
              {t(`page_courses.variant${card.variant}`)}
            </span>
          </div>
          <p className="text-sm text-oiqLightGreen-400">
            {t('shared.intlDateTime', {
              val: new Date(card.updatedAt),
              format: 'ru-RU',
            })}
          </p>
        </div>
        <div className="px-3 sm:px-14">
          <div className="mb-4 aspect-video overflow-hidden rounded-md">
            <img
              src={card.imageUrl}
              className="h-full w-full border-none object-cover"
              alt={card.title}
            />
          </div>
        </div>
        <div
          className={`mb-4 flex px-3 text-center font-formularNeutral sm:px-14 ${
            card.titleHelperUnder ? 'flex-col-reverse' : 'flex-col'
          }`}
        >
          <p
            className="text-base"
            dangerouslySetInnerHTML={{
              __html: dp.sanitize(card.titleHelper),
            }}
          />
          <h3
            className="text-2xl/7 sm:text-4xl/8"
            dangerouslySetInnerHTML={{
              __html: dp.sanitize(card.title, { FORBID_ATTR: ['style'] }),
            }}
          />
        </div>
        <div className="mx-auto mt-auto flex flex-row items-center font-antonymMedium text-base">
          {card.numberOfParts >= 2 ? (
            <div className="flex h-full items-center rounded-l-full bg-oiqLightGreen-100 text-oiqDarkGray-900">
              <p className="pb-0.5 pl-3 pr-5">
                {t('page_courses.part_count', { count: card.numberOfParts })}
              </p>
            </div>
          ) : null}
          <div className="-ml-3 flex h-full items-center rounded-full bg-oiqDarkGray-900 dark:bg-white ">
            <p className="px-3 pb-0.5 italic text-oiqWhite-50 dark:text-oiqDarkGray-900">
              {card.numberOfLessons > 0
                ? t('page_courses.lesson_count', {
                    count: card.numberOfLessons,
                  })
                : t('page_courses.coming_soon')}
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}
