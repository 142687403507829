import dp from 'dompurify'
import { useTranslation } from 'react-i18next'
import Container from './SubscribeModalContainer'
import { Icon } from 'shared/ui/icon'

export function SubscribeModalSuccess() {
  const { t } = useTranslation()

  return (
    <Container>
      <>
        <Icon name="featureModalSuccess/OIQ" />
        <div
          className="px-2 pt-2 text-center text-xl/[1] tracking-tight text-oiqDarkGray-900 xs:text-2xl/[1] sm:text-lg/[1] lg:px-5 lg:pt-5 lg:text-xl/[1]"
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(t('feature_subscribeToNewsletter.success')),
          }}
        />
      </>
    </Container>
  )
}
