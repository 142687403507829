import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function CourseCardLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#424242' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#DAE2E9'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 888 692"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="18" y="22" rx="3" ry="3" width="36" height="30" />
      <rect x="62" y="30" rx="3" ry="3" width="79" height="21" />
      <rect x="745" y="30" rx="3" ry="3" width="115" height="21" />
      <rect x="90" y="100" rx="10" ry="10" width="700" height="378" />
      <rect x="389" y="513" rx="3" ry="3" width="110" height="21" />
      <rect x="132" y="538" rx="3" ry="3" width="624" height="50" />
      <rect x="316" y="610" rx="3" ry="3" width="256" height="44" />
    </ContentLoader>
  )
}
