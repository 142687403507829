import { createHistoryRouter } from 'atomic-router'
import { createEffect, sample } from 'effector'
import { createBrowserHistory } from 'history'
import {
  analyticsRoute,
  courseRoute,
  coursesRoute,
  homeRoute,
  lessonRoute,
  privacyPolicyRoute,
  profileRoute,
} from 'shared/routes'

const routes = [
  { path: '/', route: [homeRoute] },
  { path: '/courses', route: [coursesRoute] },
  { path: '/course/:courseSlug', route: [courseRoute] },
  { path: '/lesson/:lessonSlug', route: [lessonRoute] },
  { path: '/profile', route: [profileRoute] },
  { path: '/analytics', route: [analyticsRoute] },
  { path: '/privacy-policy', route: [privacyPolicyRoute] },
]

export const router = createHistoryRouter({ routes })

const history = createBrowserHistory()
router.setHistory(history)

const scrollToTopFx = createEffect(() => window.scrollTo(0, 0))

sample({
  clock: router.push,
  target: scrollToTopFx,
})
