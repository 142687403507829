import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function MobileMenuHeaderLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 470 160"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="10" y="10" rx="5" ry="5" width="155" height="95" />
      <rect x="170" y="20" rx="3" ry="3" width="100" height="24" />
      {/* <rect x="211" y="23" rx="3" ry="3" width="70" height="20" /> */}
      <rect x="170" y="65" rx="5" ry="5" width="200" height="30" />
      <rect x="10" y="116" rx="8" ry="8" width="450" height="34" />
    </ContentLoader>
  )
}
