import { Link } from 'atomic-router-react'
import dp from 'dompurify'
import { useUnit } from 'effector-react'
import { UserButton } from 'entity/viewer'
import { ThemeToggle } from 'feature/themeToggle'
import { useTranslation } from 'react-i18next'
import { modal } from 'shared/modal'
import { analyticsRoute, coursesRoute, homeRoute } from 'shared/routes'
import { SignInForm } from 'widget/authForm'
import { fetchHeaderWidget } from './model'
import { LanguageSwitch } from 'feature/languageSwitch'

export default function Header() {
  const { t } = useTranslation()
  const { data } = useUnit(fetchHeaderWidget)

  const isAuthorized = Boolean(data?.viewer)

  return (
    <div className="sticky top-0 z-20 w-full">
      <div className="before:absolute before:-inset-x-0 before:-bottom-1/3 before:-top-px before:bg-oiqLightGreen-400/[.12] before:backdrop-blur-md before:content-[''] before:gradient-mask-b-50 before:dark:bg-oiqDarkGray-900/[.12]"></div>
      <header className="relative mb-4 flex h-18 items-center justify-between px-5 py-3 font-formularNeutral xs:mb-5 xs:h-25 sm:h-22">
        {/* DESKTOP */}
        <div className="hidden flex-row items-center sm:flex">
          <Link
            className="flex cursor-pointer items-center rounded focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
            to={homeRoute}
          >
            <div className="flex flex-row items-center">
              <svg
                className="h-16 fill-oiqDarkGray-900 px-2 dark:fill-oiqLightGreen-400"
                viewBox="0 0 211 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="0iq logo"
              >
                <g id="Group">
                  <path
                    id="Vector"
                    d="M121.161 0.791016H89.2743C88.9286 0.791016 88.6484 1.08786 88.6484 1.45403V87.3909C88.6484 87.7571 88.9286 88.0539 89.2743 88.0539H121.161C121.507 88.0539 121.787 87.7571 121.787 87.3909V1.45403C121.787 1.08786 121.507 0.791016 121.161 0.791016Z"
                  />
                  <path
                    id="Vector_2"
                    d="M196.979 59.083C196.804 58.7276 196.914 58.298 197.225 58.0752C204.049 53.1954 208.439 45.1544 208.439 33.4377C208.439 9.15556 190.666 1.67147 174.129 0.865248V0.791016H164.211V0.801602C147.139 1.09333 127.898 8.1743 127.898 33.4377C127.898 54.8079 142.503 63.9627 159.845 63.9627H165.488C165.858 63.9627 166.199 64.1908 166.364 64.5462L176.808 87.5925C176.933 87.8736 177.203 88.0539 177.499 88.0539H209.531C209.876 88.0539 210.157 87.7569 210.157 87.3909V85.8951C210.157 85.7625 210.127 85.6353 210.066 85.5186L196.979 59.0883V59.083ZM174.294 38.6675H160.757C157.838 38.6728 155.475 36.1693 155.475 33.077C155.475 29.9847 157.838 27.4812 160.757 27.4812H174.194C177.113 27.4812 179.476 29.9847 179.476 33.077C179.476 36.1693 177.168 38.6092 174.299 38.6675H174.294Z"
                  />
                  <path
                    id="Vector_3"
                    d="M45.1154 0.914264C43.2229 0.755141 39.3628 0.755141 37.3652 0.914264C16.8732 2.56384 0.15625 15.718 0.15625 45.824C0.15625 75.93 16.8532 89.4024 37.3652 90.7337C39.3127 90.861 43.1928 90.861 45.1154 90.7337C65.6273 89.3865 82.3243 76.0043 82.3243 45.824C82.3243 15.6437 65.5973 2.65401 45.1154 0.914264Z"
                  />
                </g>
              </svg>
              <div
                className="ml-2 flex items-center px-1 text-center text-xs uppercase leading-none tracking-[0.01em] text-oiqDarkGray-900 dark:text-oiqWhite-50"
                dangerouslySetInnerHTML={{
                  __html: dp.sanitize(t('widget_header.descriptor')),
                }}
              />
            </div>
          </Link>
          <nav className="hidden space-x-4 px-6 text-sm uppercase md:block">
            <Link
              className="inline-block cursor-pointer rounded-full bg-oiqLime-500 px-5 py-2 text-oiqDarkGray-900 transition hover:bg-oiqLime-400 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
              to={coursesRoute}
            >
              {t('widget_header.navCourses')}
            </Link>

            {data?.hasAnalytics && (
              <Link
                className="inline-block cursor-pointer rounded-full bg-oiqLime-500 px-5 py-2 text-oiqDarkGray-900 transition hover:bg-oiqLime-400 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
                to={analyticsRoute}
              >
                {t('widget_header.navAnalytics')}
              </Link>
            )}
          </nav>
        </div>

        {/* MOBILE */}
        <div className="flex flex-row items-center sm:hidden">
          <Link className="flex cursor-pointer items-center" to={homeRoute}>
            <div className="flex flex-row items-center">
              <svg
                className="w-[30vw] fill-oiqDarkGray-900 px-2 py-0.5 dark:fill-oiqLightGreen-400"
                viewBox="0 0 211 91"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-label="0iq logo"
              >
                <g id="Group">
                  <path
                    id="Vector"
                    d="M121.161 0.791016H89.2743C88.9286 0.791016 88.6484 1.08786 88.6484 1.45403V87.3909C88.6484 87.7571 88.9286 88.0539 89.2743 88.0539H121.161C121.507 88.0539 121.787 87.7571 121.787 87.3909V1.45403C121.787 1.08786 121.507 0.791016 121.161 0.791016Z"
                  />
                  <path
                    id="Vector_2"
                    d="M196.979 59.083C196.804 58.7276 196.914 58.298 197.225 58.0752C204.049 53.1954 208.439 45.1544 208.439 33.4377C208.439 9.15556 190.666 1.67147 174.129 0.865248V0.791016H164.211V0.801602C147.139 1.09333 127.898 8.1743 127.898 33.4377C127.898 54.8079 142.503 63.9627 159.845 63.9627H165.488C165.858 63.9627 166.199 64.1908 166.364 64.5462L176.808 87.5925C176.933 87.8736 177.203 88.0539 177.499 88.0539H209.531C209.876 88.0539 210.157 87.7569 210.157 87.3909V85.8951C210.157 85.7625 210.127 85.6353 210.066 85.5186L196.979 59.0883V59.083ZM174.294 38.6675H160.757C157.838 38.6728 155.475 36.1693 155.475 33.077C155.475 29.9847 157.838 27.4812 160.757 27.4812H174.194C177.113 27.4812 179.476 29.9847 179.476 33.077C179.476 36.1693 177.168 38.6092 174.299 38.6675H174.294Z"
                  />
                  <path
                    id="Vector_3"
                    d="M45.1154 0.914264C43.2229 0.755141 39.3628 0.755141 37.3652 0.914264C16.8732 2.56384 0.15625 15.718 0.15625 45.824C0.15625 75.93 16.8532 89.4024 37.3652 90.7337C39.3127 90.861 43.1928 90.861 45.1154 90.7337C65.6273 89.3865 82.3243 76.0043 82.3243 45.824C82.3243 15.6437 65.5973 2.65401 45.1154 0.914264Z"
                  />
                </g>
              </svg>
              <div
                className="flex items-center text-[2.6vw] uppercase leading-none tracking-[0.01em] text-oiqDarkGray-900 dark:text-oiqWhite-50"
                dangerouslySetInnerHTML={{
                  __html: dp.sanitize(t('widget_header.mobDescriptor')),
                }}
              />
            </div>
          </Link>
        </div>

        <div className="hidden flex-row items-center gap-6 sm:flex">
          <div className="inline-flex items-center gap-3">
            {isAuthorized && <UserButton viewer={data?.viewer} />}
            {!isAuthorized && (
              <>
                <button
                  className="rounded font-formularNeutral text-xs uppercase text-oiqDarkGray-900 hover:text-oiqLime-500 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 dark:text-white dark:hover:text-oiqLime-500"
                  onClick={() => modal.open(<SignInForm />)}
                >
                  {t('widget_header.signIn')}
                </button>
                {/* <div className="font-formularNeutral text-xs text-oiqDarkGray-900 dark:text-white">
                  |
                </div>
                <button
                  className="rounded p-0.5 font-formularNeutral text-xs uppercase text-oiqDarkGray-900 hover:text-oiqLime-500 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 dark:text-white dark:hover:text-oiqLime-500"
                  // onClick={() => openSignUp()}
                >
                  {t('widget_header.signUp')}
                </button> */}
              </>
            )}
          </div>
          <LanguageSwitch />
          <ThemeToggle />
        </div>
      </header>
    </div>
  )
}
