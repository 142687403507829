import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function AsideHeaderLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 470 160"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="10" y="10" rx="5" ry="5" width="155" height="95" />
      <rect x="170" y="15" rx="3" ry="3" width="35" height="28" />
      <rect x="210" y="21" rx="3" ry="3" width="70" height="22" />
      <rect x="170" y="65" rx="5" ry="5" width="200" height="35" />
      <rect x="10" y="116" rx="16" ry="16" width="450" height="32" />
    </ContentLoader>
  )
}
