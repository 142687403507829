import { Switch as HeadlessSwitch } from '@headlessui/react'
import * as Switch from '@radix-ui/react-switch'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { $theme } from 'shared/theme'
import dark from './assets/dark.svg'
import light from './assets/light.svg'
import { toggleTheme } from './model'

export function ThemeToggle() {
  const theme = useUnit($theme)
  const enabled = theme === 'dark'
  const { t } = useTranslation()

  return (
    <Switch.Root
      checked={enabled}
      onCheckedChange={() => toggleTheme()}
      className={`${
        enabled ? 'bg-oiqDarkGray-900' : 'bg-oiqLime-500'
      } relative inline-flex h-6 w-11 items-center rounded-full border border-oiqLime-500 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400`}
    >
      <Switch.Thumb
        className={`${
          enabled ? 'translate-x-5' : 'translate-x-0.5'
        } inline-block h-5 w-5 transform rounded-full transition`}
      >
        <span className="sr-only">{t('feature_themeToggle.toggleTheme')}</span>
        <img
          src={enabled ? dark : light}
          alt={t('feature_themeToggle.themeToggler')}
          className="absolute inset-0 m-auto h-5 w-5"
        />
      </Switch.Thumb>
    </Switch.Root>
  )
}

export function ThemeButton() {
  const theme = useUnit($theme)
  const enabled = theme === 'dark'
  const { t } = useTranslation()

  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={() => toggleTheme()}
      className="inline-flex items-center gap-3 rounded-full p-0.5 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
    >
      <span className="sr-only">{t('feature_themeToggle.toggleTheme')}</span>
      <img
        src={enabled ? light : dark}
        alt={t('feature_themeToggle.themeToggler')}
        className="m-auto h-12 w-12 transition"
      />
      <span className="inline-block text-left text-base/[1] uppercase tracking-wide transition">
        {enabled
          ? t('feature_themeToggle.lightTheme')
          : t('feature_themeToggle.nightTheme')}
      </span>
    </HeadlessSwitch>
  )
}
