import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function MobileMenuContentLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 375 400"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="20" y="20" rx="5" ry="5" width="80" height="20" />
      <rect x="110" y="20" rx="10" ry="10" width="80" height="20" />

      <rect x="20" y="65" rx="5" ry="5" width="280" height="15" />
      <rect x="20" y="85" rx="8" ry="8" width="60" height="16" />
      <rect x="20" y="115" rx="5" ry="5" width="335" height="1" />

      <rect x="20" y="135" rx="5" ry="5" width="280" height="15" />
      <rect x="20" y="155" rx="8" ry="8" width="60" height="16" />
      <rect x="20" y="185" rx="5" ry="5" width="335" height="1" />

      <rect x="20" y="205" rx="5" ry="5" width="280" height="15" />
      <rect x="20" y="225" rx="8" ry="8" width="60" height="16" />
      <rect x="20" y="255" rx="5" ry="5" width="335" height="1" />

      <rect x="20" y="275" rx="5" ry="5" width="280" height="15" />
      <rect x="20" y="295" rx="8" ry="8" width="60" height="16" />
      <rect x="20" y="325" rx="5" ry="5" width="335" height="1" />

      <rect x="20" y="345" rx="5" ry="5" width="280" height="15" />
      <rect x="20" y="365" rx="8" ry="8" width="60" height="16" />
      {/* <rect x="20" y="395" rx="5" ry="5" width="335" height="1" /> */}
    </ContentLoader>
  )
}
