import dp from 'dompurify'
import { useTranslation } from 'react-i18next'

export default function Footer() {
  return (
    <div className="my-5 font-antonymMedium text-oiqDarkGray-900">
      {/* DESKTOP */}
      <div className="m-auto hidden h-auto w-11/12 max-w-6xl sm:block">
        <div className="flex flex-row items-end justify-between pb-5 pt-6">
          <div className="w-[15%]">
            <svg
              className="h-auto w-24"
              width="137"
              height="59"
              viewBox="0 0 137 59"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M78.7339 0H57.9853C57.7604 0 57.5781 0.193152 57.5781 0.431416V56.3498C57.5781 56.5881 57.7604 56.7812 57.9853 56.7812H78.7339C78.9588 56.7812 79.1411 56.5881 79.1411 56.3498V0.431416C79.1411 0.193152 78.9588 0 78.7339 0Z"
                fill="#171717"
                className="dark:fill-oiqLightGreen-400"
              />
              <path
                d="M128.068 37.9318C128.068 37.9307 128.067 37.9296 128.067 37.9285C127.954 37.6976 128.026 37.419 128.227 37.2744C132.667 34.0991 135.524 28.8669 135.524 21.2429C135.524 5.45423 123.976 0.579993 113.223 0.0494539C113.209 0.0488037 113.199 0.0379347 113.199 0.0247397C113.199 0.0110763 113.188 0 113.174 0H106.749C106.747 0 106.746 0.00155518 106.746 0.00347359C106.746 0.00536886 106.744 0.00691405 106.742 0.00694659C95.6341 0.197652 83.1172 4.80592 83.1172 21.2429C83.1172 35.1483 92.62 41.1053 103.905 41.1053H107.576C107.817 41.1053 108.039 41.2537 108.146 41.485L114.942 56.481C115.024 56.6639 115.199 56.7812 115.392 56.7812H136.235C136.459 56.7812 136.642 56.588 136.642 56.3498V55.3766C136.642 55.2903 136.622 55.2075 136.583 55.1315L128.068 37.9351C128.068 37.9341 128.068 37.933 128.068 37.9318ZM113.307 24.6459H104.498C102.598 24.6494 101.061 23.0204 101.061 21.0082C101.061 18.9961 102.598 17.3671 104.498 17.3671H113.242C115.141 17.3671 116.678 18.9961 116.678 21.0082C116.678 23.0204 115.177 24.608 113.31 24.6459H113.307Z"
                fill="#171717"
                className="dark:fill-oiqLightGreen-400"
              />
              <path
                d="M29.2545 0.0776549C28.0231 -0.025885 25.5114 -0.025885 24.2115 0.0776549C10.8776 1.15102 0 9.71031 0 29.3001C0 48.8898 10.8646 57.6562 24.2115 58.5224C25.4788 58.6053 28.0035 58.6053 29.2545 58.5224C42.6015 57.6458 53.4661 48.9381 53.4661 29.3001C53.4661 9.66199 42.5819 1.20969 29.2545 0.0776549Z"
                fill="#171717"
                className="dark:fill-oiqLightGreen-400"
              />
            </svg>
          </div>
          <Copyright />
          <div className="w-[15%]" />
        </div>
      </div>

      {/* MOBILE */}
      <div className="m-auto h-auto w-11/12 sm:hidden">
        <div className="flex flex-col items-center py-5">
          <svg
            className="h-auto w-[28%]"
            width="137"
            height="59"
            viewBox="0 0 137 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M78.7339 0H57.9853C57.7604 0 57.5781 0.193152 57.5781 0.431416V56.3498C57.5781 56.5881 57.7604 56.7812 57.9853 56.7812H78.7339C78.9588 56.7812 79.1411 56.5881 79.1411 56.3498V0.431416C79.1411 0.193152 78.9588 0 78.7339 0Z"
              fill="#171717"
              className="dark:fill-oiqLightGreen-400"
            />
            <path
              d="M128.068 37.9318C128.068 37.9307 128.067 37.9296 128.067 37.9285C127.954 37.6976 128.026 37.419 128.227 37.2744C132.667 34.0991 135.524 28.8669 135.524 21.2429C135.524 5.45423 123.976 0.579993 113.223 0.0494539C113.209 0.0488037 113.199 0.0379347 113.199 0.0247397C113.199 0.0110763 113.188 0 113.174 0H106.749C106.747 0 106.746 0.00155518 106.746 0.00347359C106.746 0.00536886 106.744 0.00691405 106.742 0.00694659C95.6341 0.197652 83.1172 4.80592 83.1172 21.2429C83.1172 35.1483 92.62 41.1053 103.905 41.1053H107.576C107.817 41.1053 108.039 41.2537 108.146 41.485L114.942 56.481C115.024 56.6639 115.199 56.7812 115.392 56.7812H136.235C136.459 56.7812 136.642 56.588 136.642 56.3498V55.3766C136.642 55.2903 136.622 55.2075 136.583 55.1315L128.068 37.9351C128.068 37.9341 128.068 37.933 128.068 37.9318ZM113.307 24.6459H104.498C102.598 24.6494 101.061 23.0204 101.061 21.0082C101.061 18.9961 102.598 17.3671 104.498 17.3671H113.242C115.141 17.3671 116.678 18.9961 116.678 21.0082C116.678 23.0204 115.177 24.608 113.31 24.6459H113.307Z"
              fill="#171717"
              className="dark:fill-oiqLightGreen-400"
            />
            <path
              d="M29.2545 0.0776549C28.0231 -0.025885 25.5114 -0.025885 24.2115 0.0776549C10.8776 1.15102 0 9.71031 0 29.3001C0 48.8898 10.8646 57.6562 24.2115 58.5224C25.4788 58.6053 28.0035 58.6053 29.2545 58.5224C42.6015 57.6458 53.4661 48.9381 53.4661 29.3001C53.4661 9.66199 42.5819 1.20969 29.2545 0.0776549Z"
              fill="#171717"
              className="dark:fill-oiqLightGreen-400"
            />
          </svg>
          <Copyright />
        </div>
      </div>
    </div>
  )
}

function Copyright() {
  const { t } = useTranslation()

  return (
    <p
      className="mt-5 text-center text-sm/[1] dark:text-oiqLightGreen-400 xs:text-base/[1] sm:mt-0 sm:text-[0.7rem]/[1]"
      dangerouslySetInnerHTML={{
        __html: dp.sanitize(t('widget_footer.copyright')),
      }}
    />
  )
}
