import {
  cache,
  createQuery,
  keepFresh,
  localStorageCache,
} from '@farfetched/core'
import { createEvent } from 'effector'
import { sdk } from 'shared/api'

export const refetch = createEvent()

export const fetchCoursesPage = createQuery({
  handler: async () => {
    const data = await sdk.CoursesPage({ in: { locale: 'ru' } })
    return data.coursesPage
  },
})

cache(fetchCoursesPage, { adapter: localStorageCache() })
keepFresh(fetchCoursesPage, { automatically: true, triggers: [refetch] })

fetchCoursesPage.start()
