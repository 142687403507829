import { SubscribeToNewsletter } from 'feature/subscribeToNewsletter'
import { Footer } from 'widget/footer'
import { Header } from 'widget/header'
import WeAreCommunity from './ui/WeAreCommunity'
import WelcomeBanner from './ui/WelcomeBanner'

export default function HomePage() {
  return (
    <div className="overflow-clip">
      <Header />
      <section className="mx-auto flex w-11/12 max-w-6xl flex-col items-center pt-6">
        <WelcomeBanner />
        <WeAreCommunity />
      </section>
      <SubscribeToNewsletter />
      <Footer />
    </div>
  )
}
