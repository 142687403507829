import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { cn } from 'shared/utils'
import { Icon } from './icon'

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      props.checked
        ? 'border-none bg-oiqDarkGray-900'
        : 'bg-oiqLightBlue-200 dark:bg-oiqLightGreen-400',
      `group relative h-6 w-6 rounded-[50%] border-[1.55px] border-oiqDarkGray-900 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-oiqLime-400 focus-visible:ring-offset-1 focus-visible:ring-offset-oiqLightBlue-100 sm:h-5 sm:w-5 sm:border-[1px] focus-visible:dark:ring-offset-oiqLightGreen-400`
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator>
      <div className="flex h-full items-center justify-center">
        <Icon
          className={`${
            props.checked ? 'text-white/[.92]' : 'text-transparent'
          } w-[17px] scale-125 transition-transform duration-300`}
          name="sharedCheckbox/checkmark"
        />
      </div>
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
