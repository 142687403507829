import { createEffect, createEvent, sample } from 'effector'
import { sdk } from 'shared/api'

export const enroll = createEvent<string>()

export const enrollFx = createEffect(async (courseId: string) =>
  sdk.EnrollCourse({ in: { courseId } })
)

sample({
  clock: enroll,
  target: enrollFx,
})
