import { GraphQLClient } from 'graphql-request'
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Map: { input: any; output: any }
  Time: { input: any; output: any }
}

export type Author = Node & {
  __typename?: 'Author'
  avatarUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Course = Node & {
  __typename?: 'Course'
  authors: Array<Author>
  cardImageUrl: Scalars['String']['output']
  cardTitleHelper: Scalars['String']['output']
  cardTitleHelperUnder: Scalars['Boolean']['output']
  description: Scalars['String']['output']
  desktopImageUrl: Scalars['String']['output']
  duration: Scalars['Int']['output']
  goal: Scalars['String']['output']
  id: Scalars['ID']['output']
  locale: Scalars['String']['output']
  mobileImageUrl: Scalars['String']['output']
  parts: Array<Part>
  published: Scalars['Boolean']['output']
  slug: Scalars['String']['output']
  state: CourseState
  tags: Array<Scalars['String']['output']>
  title: Scalars['String']['output']
  titleHelper: Scalars['String']['output']
  titleHelperUnder: Scalars['Boolean']['output']
  variant: CourseVariant
}

export type CourseCard = Node & {
  __typename?: 'CourseCard'
  courseId: Scalars['ID']['output']
  courseState: CourseState
  id: Scalars['ID']['output']
  imageUrl: Scalars['String']['output']
  numberOfLessons: Scalars['Int']['output']
  numberOfParts: Scalars['Int']['output']
  slug: Scalars['String']['output']
  title: Scalars['String']['output']
  titleHelper: Scalars['String']['output']
  titleHelperUnder: Scalars['Boolean']['output']
  updatedAt: Scalars['Time']['output']
  variant: CourseVariant
}

export type CoursePageIn = {
  locale?: InputMaybe<Scalars['String']['input']>
  slug: Scalars['String']['input']
}

export type CoursePageOut = {
  __typename?: 'CoursePageOut'
  course: Course
  isAuthorized: Scalars['Boolean']['output']
}

export enum CourseState {
  ComingSoon = 'ComingSoon',
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export enum CourseVariant {
  Course = 'Course',
  Practice = 'Practice',
  SideQuest = 'SideQuest',
}

export type CoursesPageIn = {
  locale?: InputMaybe<Scalars['String']['input']>
}

export type CoursesPageOut = {
  __typename?: 'CoursesPageOut'
  courseCards: Array<CourseCard>
}

export type DropOutCourseIn = {
  courseId: Scalars['ID']['input']
}

export type DropOutCourseOut = {
  __typename?: 'DropOutCourseOut'
  errorKey?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type EnrollCourseIn = {
  courseId: Scalars['ID']['input']
}

export type EnrollCourseOut = {
  __typename?: 'EnrollCourseOut'
  errorKey?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type HeaderWidgetOut = {
  __typename?: 'HeaderWidgetOut'
  hasAnalytics: Scalars['Boolean']['output']
  viewer?: Maybe<User>
}

export type Lesson = Node & {
  __typename?: 'Lesson'
  duration: Scalars['Int']['output']
  id: Scalars['ID']['output']
  imageUrl: Scalars['String']['output']
  slug: Scalars['String']['output']
  state: LessonState
  title: Scalars['String']['output']
}

export enum LessonState {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
}

export type MobileMenuWidgetOut = {
  __typename?: 'MobileMenuWidgetOut'
  hasAnalytics: Scalars['Boolean']['output']
  viewer?: Maybe<User>
}

export type Mutation = {
  __typename?: 'Mutation'
  dropOutCourse: DropOutCourseOut
  enrollCourse: EnrollCourseOut
  subscribeToNewsletter: SubscribeToNewsletterOut
  unsubscribeFromNewsletter: UnsubscribeFromNewsletterOut
}

export type MutationDropOutCourseArgs = {
  in: DropOutCourseIn
}

export type MutationEnrollCourseArgs = {
  in: EnrollCourseIn
}

export type MutationSubscribeToNewsletterArgs = {
  in: SubscribeToNewsletterIn
}

export type MutationUnsubscribeFromNewsletterArgs = {
  in: UnsubscribeFromNewsletterIn
}

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID']['output']
}

export type Part = Node & {
  __typename?: 'Part'
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  lessons: Array<Lesson>
  title: Scalars['String']['output']
}

export type ProfilePageOut = {
  __typename?: 'ProfilePageOut'
  recommendedCourseCards: Array<CourseCard>
  userCourseCards: Array<CourseCard>
  viewer: User
}

export type Query = {
  __typename?: 'Query'
  coursePage: CoursePageOut
  coursesPage: CoursesPageOut
  headerWidget: HeaderWidgetOut
  mobileMenuWidget: MobileMenuWidgetOut
  profilePage: ProfilePageOut
}

export type QueryCoursePageArgs = {
  in: CoursePageIn
}

export type QueryCoursesPageArgs = {
  in: CoursesPageIn
}

export type SubscribeToNewsletterIn = {
  email: Scalars['String']['input']
}

export type SubscribeToNewsletterOut = {
  __typename?: 'SubscribeToNewsletterOut'
  errorKey?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type UnsubscribeFromNewsletterIn = {
  code: Scalars['String']['input']
}

export type UnsubscribeFromNewsletterOut = {
  __typename?: 'UnsubscribeFromNewsletterOut'
  errorKey?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type User = Node & {
  __typename?: 'User'
  avatarUrl: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type CoursePageQueryVariables = Exact<{
  in: CoursePageIn
}>

export type CoursePageQuery = {
  __typename?: 'Query'
  coursePage: {
    __typename?: 'CoursePageOut'
    isAuthorized: boolean
    course: {
      __typename?: 'Course'
      id: string
      slug: string
      title: string
      titleHelper: string
      titleHelperUnder: boolean
      description: string
      duration: number
      goal: string
      tags: Array<string>
      desktopImageUrl: string
      mobileImageUrl: string
      state: CourseState
      parts: Array<{
        __typename?: 'Part'
        id: string
        title: string
        description: string
        lessons: Array<{
          __typename?: 'Lesson'
          id: string
          slug: string
          title: string
          imageUrl: string
          duration: number
        }>
      }>
      authors: Array<{
        __typename?: 'Author'
        id: string
        name: string
        avatarUrl: string
      }>
    }
  }
}

export type CoursesPageQueryVariables = Exact<{
  in: CoursesPageIn
}>

export type CoursesPageQuery = {
  __typename?: 'Query'
  coursesPage: {
    __typename?: 'CoursesPageOut'
    courseCards: Array<{
      __typename?: 'CourseCard'
      id: string
      slug: string
      title: string
      titleHelper: string
      titleHelperUnder: boolean
      imageUrl: string
      variant: CourseVariant
      numberOfParts: number
      numberOfLessons: number
      updatedAt: any
    }>
  }
}

export type ProfilePageQueryVariables = Exact<{ [key: string]: never }>

export type ProfilePageQuery = {
  __typename?: 'Query'
  profilePage: {
    __typename?: 'ProfilePageOut'
    viewer: { __typename?: 'User'; id: string; name: string; avatarUrl: string }
    userCourseCards: Array<{
      __typename?: 'CourseCard'
      id: string
      courseId: string
      courseState: CourseState
      slug: string
      title: string
      titleHelper: string
      titleHelperUnder: boolean
      imageUrl: string
      variant: CourseVariant
      numberOfParts: number
      numberOfLessons: number
      updatedAt: any
    }>
    recommendedCourseCards: Array<{
      __typename?: 'CourseCard'
      id: string
      courseId: string
      courseState: CourseState
      slug: string
      title: string
      titleHelper: string
      titleHelperUnder: boolean
      imageUrl: string
      variant: CourseVariant
      numberOfParts: number
      numberOfLessons: number
      updatedAt: any
    }>
  }
}

export type HeaderWidgetQueryVariables = Exact<{ [key: string]: never }>

export type HeaderWidgetQuery = {
  __typename?: 'Query'
  headerWidget: {
    __typename?: 'HeaderWidgetOut'
    hasAnalytics: boolean
    viewer?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl: string
    } | null
  }
}

export type MobileMenuWidgetQueryVariables = Exact<{ [key: string]: never }>

export type MobileMenuWidgetQuery = {
  __typename?: 'Query'
  mobileMenuWidget: {
    __typename?: 'MobileMenuWidgetOut'
    hasAnalytics: boolean
    viewer?: {
      __typename?: 'User'
      id: string
      name: string
      avatarUrl: string
    } | null
  }
}

export type EnrollCourseMutationVariables = Exact<{
  in: EnrollCourseIn
}>

export type EnrollCourseMutation = {
  __typename?: 'Mutation'
  enrollCourse: {
    __typename?: 'EnrollCourseOut'
    success: boolean
    errorKey?: string | null
  }
}

export type SubscribeMutationVariables = Exact<{
  in: SubscribeToNewsletterIn
}>

export type SubscribeMutation = {
  __typename?: 'Mutation'
  subscribeToNewsletter: {
    __typename?: 'SubscribeToNewsletterOut'
    success: boolean
  }
}

export const CoursePageDocument = gql`
  query CoursePage($in: CoursePageIn!) {
    coursePage(in: $in) {
      isAuthorized
      course {
        id
        slug
        title
        titleHelper
        titleHelperUnder
        description
        duration
        goal
        tags
        desktopImageUrl
        mobileImageUrl
        parts {
          id
          title
          description
          lessons {
            id
            slug
            title
            imageUrl
            duration
          }
        }
        authors {
          id
          name
          avatarUrl
        }
        state
      }
    }
  }
`
export const CoursesPageDocument = gql`
  query CoursesPage($in: CoursesPageIn!) {
    coursesPage(in: $in) {
      courseCards {
        id
        slug
        title
        titleHelper
        titleHelperUnder
        imageUrl
        variant
        numberOfParts
        numberOfLessons
        updatedAt
      }
    }
  }
`
export const ProfilePageDocument = gql`
  query ProfilePage {
    profilePage {
      viewer {
        id
        name
        avatarUrl
      }
      userCourseCards {
        id
        courseId
        courseState
        slug
        title
        titleHelper
        titleHelperUnder
        imageUrl
        variant
        numberOfParts
        numberOfLessons
        updatedAt
      }
      recommendedCourseCards {
        id
        courseId
        courseState
        slug
        title
        titleHelper
        titleHelperUnder
        imageUrl
        variant
        numberOfParts
        numberOfLessons
        updatedAt
      }
    }
  }
`
export const HeaderWidgetDocument = gql`
  query HeaderWidget {
    headerWidget {
      viewer {
        id
        name
        avatarUrl
      }
      hasAnalytics
    }
  }
`
export const MobileMenuWidgetDocument = gql`
  query MobileMenuWidget {
    mobileMenuWidget {
      viewer {
        id
        name
        avatarUrl
      }
      hasAnalytics
    }
  }
`
export const EnrollCourseDocument = gql`
  mutation EnrollCourse($in: EnrollCourseIn!) {
    enrollCourse(in: $in) {
      success
      errorKey
    }
  }
`
export const SubscribeDocument = gql`
  mutation Subscribe($in: SubscribeToNewsletterIn!) {
    subscribeToNewsletter(in: $in) {
      success
    }
  }
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  variables
) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    CoursePage(
      variables: CoursePageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CoursePageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CoursePageQuery>(CoursePageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CoursePage',
        'query',
        variables
      )
    },
    CoursesPage(
      variables: CoursesPageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<CoursesPageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CoursesPageQuery>(CoursesPageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'CoursesPage',
        'query',
        variables
      )
    },
    ProfilePage(
      variables?: ProfilePageQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<ProfilePageQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ProfilePageQuery>(ProfilePageDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'ProfilePage',
        'query',
        variables
      )
    },
    HeaderWidget(
      variables?: HeaderWidgetQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<HeaderWidgetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<HeaderWidgetQuery>(HeaderWidgetDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'HeaderWidget',
        'query',
        variables
      )
    },
    MobileMenuWidget(
      variables?: MobileMenuWidgetQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<MobileMenuWidgetQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MobileMenuWidgetQuery>(
            MobileMenuWidgetDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'MobileMenuWidget',
        'query',
        variables
      )
    },
    EnrollCourse(
      variables: EnrollCourseMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<EnrollCourseMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EnrollCourseMutation>(
            EnrollCourseDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'EnrollCourse',
        'mutation',
        variables
      )
    },
    Subscribe(
      variables: SubscribeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders
    ): Promise<SubscribeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SubscribeMutation>(SubscribeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'Subscribe',
        'mutation',
        variables
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
