import { useTranslation } from 'react-i18next'
import { Icon } from 'shared/ui/icon'

export default function WelcomeBanner() {
  const { t } = useTranslation()

  return (
    <div className="relative mb-10 flex aspect-[0.65/1] w-full flex-col items-center justify-between overflow-hidden rounded-lg bg-white pb-[15%] pt-[50%] dark:bg-oiqLightBlue-200 sm:aspect-[2/1] sm:pb-[3%] sm:pr-4 sm:pt-[13%]">
      <div className="flex justify-center">
        <img
          className="absolute -right-[15%] top-[4%] h-auto w-[55%] xs:top-[6%] sm:right-[9%] sm:top-[8%] sm:w-[14%]"
          src={t('page_home.ringImageUrl')}
          alt="zdarov, bratishka"
        />
        <img
          className="relative h-auto w-[86%] sm:w-1/2"
          src="https://imagedelivery.net/POP9xpSKQlswAeVv4Xp89Q/6097b4e0-b22c-475a-4361-21cc26b51500/public"
          alt="0iq"
        />
      </div>
      <div className="flex flex-col items-center">
        <h2 className="mx-auto mb-4 px-4 text-center font-formularNeutral text-3xl/[1] tracking-tight dark:text-oiqDarkGray-800 xs:w-4/5 sm:w-full sm:text-xl/[1] lg:text-2xl/[1]">
          {t('page_home.happyCoding')}
        </h2>
        <Icon className="h-7 w-7 sm:h-6 sm:w-6" name="pageHome/ctaArrowDown" />
      </div>
    </div>
  )
}
