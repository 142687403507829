type Props = {
  progress: number //percents
  label?: string
  large?: boolean
}
export function Progress({ progress, label, large }: Props) {
  const borderProgress =
    progress === 100
      ? 'rounded-lg sm:rounded-full'
      : 'rounded-l-lg sm:rounded-l-full'
  const height = large ? 'sm:h-7' : 'sm:h-5'
  const text = large ? 'sm:text-sm' : 'sm:text-xs'
  return (
    <div
      className={`${height} relative flex h-7 items-center justify-center whitespace-nowrap rounded-lg bg-oiqLightBlue-200 font-antonymMedium dark:bg-oiqGray-200 xs:h-8 sm:rounded-full`}
    >
      <div
        className={`${borderProgress} absolute left-0 top-0 h-full bg-oiqLime-500 text-center text-oiqDarkGray-900`}
        style={{ width: `${progress}%` }}
      ></div>
      <div
        className={`absolute text-base text-oiqDarkGray-900 xs:text-lg ${text}`}
      >
        {label}
      </div>
    </div>
  )
}
