import {
  cache,
  createQuery,
  keepFresh,
  localStorageCache,
} from '@farfetched/core'
import { createEvent } from 'effector'
import { sdk } from 'shared/api'

export const refetch = createEvent()

export const fetchProfilePage = createQuery({
  handler: async () => {
    const data = await sdk.ProfilePage({ in: { locale: 'ru' } })
    return data.profilePage
  },
})

cache(fetchProfilePage, { adapter: localStorageCache() })
keepFresh(fetchProfilePage, { automatically: true, triggers: [refetch] })

fetchProfilePage.start()
