import { CourseCard, CourseState } from 'shared/gql'
import { Button } from 'shared/ui/button'
import { Icon } from 'shared/ui/icon'
import { Progress } from './progress'
import { useTranslation } from 'react-i18next'
import dp from 'dompurify'
import { courseRoute } from 'shared/routes'

type Props = {
  card: CourseCard
}

export function CourseProgressCard({ card }: Props) {
  const { t } = useTranslation()
  // const progress = (completedLessons / numberOfLessons) * 100
  const iconName =
    card.courseState === CourseState.InProgress
      ? 'pageProfile/arrow'
      : 'pageProfile/eye'

  let buttonLabel = ''
  switch (card.courseState) {
    case CourseState.InProgress:
      buttonLabel = t('page_profile.buttonCourseInProgress')
      break
    default:
      buttonLabel = t('page_profile.buttonCourseCompleted')
      break
  }

  return (
    <div
      className={`rounded-lg bg-white font-antonymMedium dark:bg-oiqDarkGray-800`}
    >
      {/* DESKTOP */}
      <div className="hidden grid-cols-[170px_repeat(5,_minmax(0,_1fr))] items-center gap-x-3 p-3 sm:grid">
        <div className="flex items-center">
          <img src={card.imageUrl} alt="" className="aspect-video" />
        </div>
        <div className="col-span-3 flex h-full flex-row items-center justify-between gap-3">
          <div className="flex h-full w-1/2 flex-col justify-evenly gap-2 py-3">
            <div className="flex flex-row items-center gap-1">
              <Icon
                name="entityCourse/course_icon"
                className="size-5 fill-current"
              />
              {t('page_profile.variantCourse')}
            </div>
            <div
              className="break-words font-formularNeutral text-xl/[1] tracking-tight"
              dangerouslySetInnerHTML={{
                __html: dp.sanitize(card.title, { FORBID_ATTR: ['style'] }),
              }}
            ></div>
          </div>
          <div className="hidden w-1/2 lg:block">
            <Progress
              progress={(0 / card.numberOfLessons) * 100}
              label={`${t('page_profile.progressBar')} ${0} / ${t(
                'page_courses.lesson_count',
                {
                  count: card.numberOfLessons,
                }
              )}`}
              large
            />
          </div>
          <div className="w-1/2 lg:hidden">
            <Progress
              progress={(0 / card.numberOfLessons) * 100}
              label={`${0} / ${card.numberOfLessons}`}
              large
            />
          </div>
        </div>
        <div className="flex pl-4">
          <div className="text-sm dark:text-oiqLightGreen-400">
            {t('13.01.2023')}
          </div>
        </div>
        <div className="flex justify-center">
          <div className="hidden lg:block">
            <Button
              variant="primary"
              onClick={() => courseRoute.open({ courseSlug: card.slug })}
            >
              {buttonLabel}
            </Button>
          </div>
          <div className="lg:hidden">
            <Button
              variant="primary"
              onClick={() => courseRoute.open({ courseSlug: card.slug })}
            >
              <Icon name={`${iconName}`} className="h-6 w-6 " />
            </Button>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="flex flex-col rounded-md pb-8 pt-2 sm:hidden">
        <div className="mb-2 flex flex-row items-center justify-between px-3">
          <div className="flex flex-row items-center">
            <Icon name="entityCourse/course_icon" />
            <span className="pl-1 text-lg xs:text-xl">курс</span>
          </div>
          {/* <p className="xs:text-lg">{date}</p> */}
        </div>
        <div className="px-3">
          <div className="mb-4 aspect-video overflow-hidden rounded-md">
            <img
              src={card.imageUrl}
              className="h-full w-full border-none object-cover"
              alt={card.title}
            />
          </div>
        </div>
        <div
          className="mb-5 px-3 text-center font-formularNeutral text-3xl/[1] tracking-tight xs:text-4xl/[1]"
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(card.title),
          }}
        ></div>
        <div className="mx-4 flex flex-col gap-2 xs:mx-8 xs:gap-3">
          <Progress
            progress={(0 / card.numberOfLessons) * 100}
            label={`${t('page_profile.progressBar')} ${0} / ${t(
              'page_courses.lesson_count',
              {
                count: card.numberOfLessons,
              }
            )}`}
          />
          <Button
            variant="primary"
            onClick={() => courseRoute.open({ courseSlug: card.slug })}
          >
            {buttonLabel}
          </Button>
        </div>
      </div>
    </div>
  )
}
