import {
  cache,
  createQuery,
  keepFresh,
  localStorageCache,
} from '@farfetched/core'
import { sample } from 'effector'

import { RouteParamsAndQuery, chainRoute } from 'atomic-router'
import { createEffect, createEvent } from 'effector'
import { sdk } from 'shared/api'
import { CourseParams, courseRoute } from 'shared/routes'
import { enrollToCourse } from 'feature/enrollToCourse'

export const refetch = createEvent()

export const fetchCoursePage = createQuery({
  handler: async (slug: string) => {
    const data = await sdk.CoursePage({ in: { locale: 'ru', slug } })
    return data.coursePage
  },
})

cache(fetchCoursePage, { adapter: localStorageCache() })
keepFresh(fetchCoursePage, { automatically: true, triggers: [refetch] })

const fetchCourseFx = createEffect(
  ({ params }: RouteParamsAndQuery<CourseParams>) => {
    fetchCoursePage.start(params.courseSlug)
  }
)

export const courseLoadedRoute = chainRoute({
  route: courseRoute,
  beforeOpen: fetchCourseFx,
})

sample({
  clock: enrollToCourse.enrollFx.done,
  fn: () => null,
  target: refetch,
})
