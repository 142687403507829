import {
  combine,
  createEffect,
  createEvent,
  createStore,
  restore,
  sample,
} from 'effector'
import { sdk } from 'shared/api'
import { z } from 'zod'
import { delay } from 'patronum/delay'
import { SubscribeModalSuccess } from './ui/SubscribeModalSuccess'
import { modal } from 'shared/modal'

type ErrorKey =
  | 'feature_subscribeToNewsletter.emailRequired'
  | 'feature_subscribeToNewsletter.emailInvalid'
  | 'feature_subscribeToNewsletter.policyAcceptRequired'
  | undefined
  | null

const formSchema = z.object({
  email: z
    .string()
    .min(1, 'feature_subscribeToNewsletter.emailRequired')
    .email('feature_subscribeToNewsletter.emailInvalid'),
  policyAccepted: z.literal(true, {
    errorMap: () => ({
      message: 'feature_subscribeToNewsletter.policyAcceptRequired',
    }),
  }),
})

export const setEmail = createEvent<string>()
export const setPolicyAccepted = createEvent<boolean>()
export const subscribe = createEvent()
const stopShaking = createEvent()

export const $email = restore(setEmail, '')
export const $policyAccepted = restore(setPolicyAccepted, false)
export const $shakeButton = createStore(false)
export const $showError = createStore(false)
export const $errorKey = createStore<ErrorKey>(null)

const $form = combine([$email, $policyAccepted], ([email, policyAccepted]) =>
  formSchema.safeParse({ email, policyAccepted })
)

const subscribeFx = createEffect(async (email: string) =>
  sdk.Subscribe({ in: { email } })
)

sample({
  source: $form,
  clock: subscribe,
  fn: (form) => !form.success,
  target: [$showError, $shakeButton],
})

sample({
  source: $form,
  clock: subscribe,
  fn: (form) => {
    const error = form.success ? null : form.error
    return error?.errors.at(0)?.message as ErrorKey
  },
  target: $errorKey,
})

sample({
  source: $form,
  clock: subscribe,
  filter: (form) => form.success,
  fn: (form) => (form.success ? form.data.email : ''),
  target: subscribeFx,
})

delay({ source: subscribe, timeout: 500, target: stopShaking })

sample({
  clock: stopShaking,
  fn: () => false,
  target: $shakeButton,
})

sample({
  clock: subscribeFx.done,
  fn: () => <SubscribeModalSuccess />,
  target: modal.open,
})

sample({
  clock: subscribeFx.done,
  fn: () => '',
  target: setEmail,
})

sample({
  clock: subscribeFx.done,
  fn: () => false,
  target: setPolicyAccepted,
})
