export interface SpritesMap {
  entityCourse: 'course_icon'
  entityViewer: 'DropDownIcon'
  featureModalSuccess: 'OIQ'
  pageHome: 'ctaArrowDown'
  pageLesson: 'lessonMobileMenuButton'
  pageProfile: 'arrow' | 'course' | 'eye' | 'whiteArrow'
  sharedCheckbox: 'checkmark'
  sharedModal: 'close'
  widgetAuthForm: 'arrowBack' | 'closeButton' | 'iconGithub' | 'iconGoogle'
  widgetMobileMenu: 'arrowRight' | 'arrowRightSolid' | 'close' | 'menu'
}
export const SPRITES_META: {
  [Key in keyof SpritesMap]: {
    filePath: string
    items: Record<
      SpritesMap[Key],
      {
        viewBox: string
        width: number
        height: number
      }
    >
  }
} = {
  entityCourse: {
    filePath: 'assets/entityCourse.a8a571f6.svg',
    items: {
      course_icon: {
        viewBox: '0 0 36 30',
        width: 36,
        height: 30,
      },
    },
  },
  entityViewer: {
    filePath: 'assets/entityViewer.5bc24f37.svg',
    items: {
      DropDownIcon: {
        viewBox: '0 0 14 8',
        width: 14,
        height: 8,
      },
    },
  },
  featureModalSuccess: {
    filePath: 'assets/featureModalSuccess.c4fe7ab2.svg',
    items: {
      OIQ: {
        viewBox: '0 0 450 190',
        width: 450,
        height: 190,
      },
    },
  },
  pageHome: {
    filePath: 'assets/pageHome.484fae2e.svg',
    items: {
      ctaArrowDown: {
        viewBox: '0 0 26 30',
        width: 26,
        height: 30,
      },
    },
  },
  pageLesson: {
    filePath: 'assets/pageLesson.579e61fe.svg',
    items: {
      lessonMobileMenuButton: {
        viewBox: '0 0 20 21',
        width: 20,
        height: 21,
      },
    },
  },
  pageProfile: {
    filePath: 'assets/pageProfile.48fca7ae.svg',
    items: {
      arrow: {
        viewBox: '0 0 30 26',
        width: 30,
        height: 26,
      },
      course: {
        viewBox: '0 0 36 30',
        width: 36,
        height: 30,
      },
      eye: {
        viewBox: '-1 -2 44 34',
        width: 40,
        height: 29,
      },
      whiteArrow: {
        viewBox: '0 0 29 24',
        width: 29,
        height: 24,
      },
    },
  },
  sharedCheckbox: {
    filePath: 'assets/sharedCheckbox.efb90a64.svg',
    items: {
      checkmark: {
        viewBox: '0 0 26 34',
        width: 26,
        height: 34,
      },
    },
  },
  sharedModal: {
    filePath: 'assets/sharedModal.f4642422.svg',
    items: {
      close: {
        viewBox: '0 0 30 29',
        width: 30,
        height: 29,
      },
    },
  },
  widgetAuthForm: {
    filePath: 'assets/widgetAuthForm.cb952e2a.svg',
    items: {
      arrowBack: {
        viewBox: '0 0 47 46',
        width: 47,
        height: 46,
      },
      closeButton: {
        viewBox: '0 0 30 29',
        width: 30,
        height: 29,
      },
      iconGithub: {
        viewBox: '0 0 30 30',
        width: 30,
        height: 30,
      },
      iconGoogle: {
        viewBox: '0 0 27 27',
        width: 27,
        height: 27,
      },
    },
  },
  widgetMobileMenu: {
    filePath: 'assets/widgetMobileMenu.2426e5e8.svg',
    items: {
      arrowRight: {
        viewBox: '0 0 31 26',
        width: 31,
        height: 26,
      },
      arrowRightSolid: {
        viewBox: '0 0 31 26',
        width: 31,
        height: 26,
      },
      close: {
        viewBox: '0 0 46 47',
        width: 46,
        height: 47,
      },
      menu: {
        viewBox: '0 0 46 47',
        width: 46,
        height: 47,
      },
    },
  },
}
