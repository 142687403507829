import { Link } from 'atomic-router-react'
import { useTranslation } from 'react-i18next'
import { CoursePageQuery } from 'shared/gql'
import { lessonRoute } from 'shared/routes'

type Props = {
  lesson: CoursePageQuery['coursePage']['course']['parts'][number]['lessons'][number]
}

export function LessonCardDesktop({ lesson }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row gap-3 border-b border-black py-3 last:border-none dark:border-oiqLightGreen-400 lg:gap-5">
      <Link
        className="relative flex aspect-video w-1/3 cursor-pointer items-center justify-center rounded-md"
        to={lessonRoute}
        params={{ lessonSlug: lesson.slug }}
      >
        <img className="aspect-video rounded-md" src={lesson.imageUrl} alt="" />
        <svg
          className="absolute w-1/5"
          width="71"
          height="43"
          viewBox="0 0 71 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.8"
            x="0.664062"
            y="0.613281"
            width="69.6797"
            height="42.1328"
            rx="10"
            fill="black"
          />
          <path
            d="M44.4922 19.9501C45.8255 20.7199 45.8255 22.6444 44.4922 23.4142L32.4976 30.3393C31.1642 31.1091 29.4976 30.1469 29.4976 28.6073L29.4976 14.7571C29.4976 13.2175 31.1642 12.2552 32.4976 13.025L44.4922 19.9501Z"
            fill="white"
          />
        </svg>
      </Link>
      <div className="flex w-2/3 flex-col justify-center">
        <Link
          className="text-xl/[1] -tracking-[0.03em] lg:text-2xl/[1]"
          to={lessonRoute}
          params={{ lessonSlug: lesson.slug }}
        >
          {lesson.title}
        </Link>
        <div className="mt-3 flex flex-row items-center gap-4 font-antonymMedium">
          <div className="flex flex-row items-center gap-1 px-1.5 text-sm/[1.4] text-oiqDarkGray-900 dark:rounded-full dark:bg-oiqGray-200">
            <svg
              className="h-2.5 w-2.5"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.79932 4.75407C9.25534 4.29806 9.51227 3.6787 9.51227 3.03381V2.83133C9.51227 2.56163 9.73092 2.34214 10.0015 2.34214H11.1449V0.382812H0.375V2.34214H1.48696C1.75665 2.34214 1.97615 2.56078 1.97615 2.83133C1.97615 3.60638 2.28413 4.34995 2.83202 4.89785L3.16468 5.2305C3.34589 5.41171 3.465 5.65163 3.47606 5.90856C3.48797 6.18847 3.38417 6.45306 3.18765 6.64958L2.62359 7.2145C2.16417 7.67391 1.91064 8.28476 1.91064 8.9356V9.13809C1.91064 9.40778 1.69199 9.62728 1.42145 9.62728H0.375V11.5866H11.144V9.62728H10.0661C9.79643 9.62728 9.57693 9.40863 9.57693 9.13809V8.9356C9.57693 8.28987 9.32085 7.67136 8.86398 7.2145L8.32204 6.67256C8.14083 6.49134 8.02172 6.25057 8.01066 5.99449C7.99875 5.71459 8.10254 5.45 8.29907 5.25347L8.79762 4.75492L8.79932 4.75407ZM7.69928 4.65113C7.32579 5.02461 7.13267 5.53167 7.16415 6.06425C7.19137 6.52452 7.40321 6.95416 7.72906 7.28L8.26419 7.81514C8.56111 8.11206 8.72786 8.51447 8.72786 8.93475V9.13724C8.72786 9.40693 8.50921 9.62643 8.23867 9.62643H3.24975C2.98006 9.62643 2.76056 9.40778 2.76056 9.13724V8.93475C2.76056 8.51192 2.92476 8.11461 3.22423 7.81599L3.75936 7.28086C4.08521 6.95501 4.2962 6.52537 4.32342 6.06511C4.3549 5.53252 4.16178 5.02546 3.78829 4.65198L3.28974 4.15343C2.99282 3.85651 2.82607 3.45409 2.82607 3.03381V2.83133C2.82607 2.56163 3.04472 2.34214 3.31526 2.34214H8.17231C8.442 2.34214 8.6615 2.56078 8.6615 2.83133V3.03381C8.6615 3.45324 8.49475 3.85566 8.19783 4.15257L7.69928 4.65113Z"
                fill="black"
              />
            </svg>
            <div className="whitespace-nowrap">
              {t('page_course.lessonDuration', { count: lesson.duration })}
            </div>
          </div>
          <div className="flex flex-row items-center gap-1 text-sm/[1]">
            <svg
              className="h-5 w-5"
              width="26"
              height="29"
              viewBox="0 0 26 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.9237" cy="15.58" r="12.58" fill="#E0FF88" />
              <path
                d="M6.87928 12.3942L10.7061 18.3278C11.357 19.3372 12.8572 19.2608 13.4023 18.1905L21.4417 2.40438"
                stroke="#171717"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
            <div>пройден</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function LessonCardMobile({ lesson }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-4 border-b border-black pb-7 pt-5 last:border-none dark:border-oiqLightGreen-400">
      <div className="relative flex aspect-video w-full cursor-pointer items-center justify-center rounded-md">
        <img className="aspect-video rounded-md" src={lesson.imageUrl} alt="" />
        <svg
          className="absolute w-1/5"
          width="71"
          height="43"
          viewBox="0 0 71 43"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            opacity="0.8"
            x="0.664062"
            y="0.613281"
            width="69.6797"
            height="42.1328"
            rx="10"
            fill="black"
          />
          <path
            d="M44.4922 19.9501C45.8255 20.7199 45.8255 22.6444 44.4922 23.4142L32.4976 30.3393C31.1642 31.1091 29.4976 30.1469 29.4976 28.6073L29.4976 14.7571C29.4976 13.2175 31.1642 12.2552 32.4976 13.025L44.4922 19.9501Z"
            fill="white"
          />
        </svg>
      </div>
      <div className="flex flex-col justify-between">
        <Link
          className="w-5/6 text-3xl/[1] tracking-tight xs:text-4xl/[1]"
          to={lessonRoute}
          params={{ lessonSlug: lesson.slug }}
        >
          {lesson.title}
        </Link>
        <div className="mt-3 flex flex-row items-center gap-4 font-antonymMedium xs:gap-5">
          <div className="flex flex-row items-center gap-1 px-2.5 text-base/[1.5] text-oiqDarkGray-900 dark:rounded-full dark:bg-oiqGray-200 xs:text-xl">
            <svg
              className="h-3 w-3 xs:h-4 xs:w-4"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.79932 4.75407C9.25534 4.29806 9.51227 3.6787 9.51227 3.03381V2.83133C9.51227 2.56163 9.73092 2.34214 10.0015 2.34214H11.1449V0.382812H0.375V2.34214H1.48696C1.75665 2.34214 1.97615 2.56078 1.97615 2.83133C1.97615 3.60638 2.28413 4.34995 2.83202 4.89785L3.16468 5.2305C3.34589 5.41171 3.465 5.65163 3.47606 5.90856C3.48797 6.18847 3.38417 6.45306 3.18765 6.64958L2.62359 7.2145C2.16417 7.67391 1.91064 8.28476 1.91064 8.9356V9.13809C1.91064 9.40778 1.69199 9.62728 1.42145 9.62728H0.375V11.5866H11.144V9.62728H10.0661C9.79643 9.62728 9.57693 9.40863 9.57693 9.13809V8.9356C9.57693 8.28987 9.32085 7.67136 8.86398 7.2145L8.32204 6.67256C8.14083 6.49134 8.02172 6.25057 8.01066 5.99449C7.99875 5.71459 8.10254 5.45 8.29907 5.25347L8.79762 4.75492L8.79932 4.75407ZM7.69928 4.65113C7.32579 5.02461 7.13267 5.53167 7.16415 6.06425C7.19137 6.52452 7.40321 6.95416 7.72906 7.28L8.26419 7.81514C8.56111 8.11206 8.72786 8.51447 8.72786 8.93475V9.13724C8.72786 9.40693 8.50921 9.62643 8.23867 9.62643H3.24975C2.98006 9.62643 2.76056 9.40778 2.76056 9.13724V8.93475C2.76056 8.51192 2.92476 8.11461 3.22423 7.81599L3.75936 7.28086C4.08521 6.95501 4.2962 6.52537 4.32342 6.06511C4.3549 5.53252 4.16178 5.02546 3.78829 4.65198L3.28974 4.15343C2.99282 3.85651 2.82607 3.45409 2.82607 3.03381V2.83133C2.82607 2.56163 3.04472 2.34214 3.31526 2.34214H8.17231C8.442 2.34214 8.6615 2.56078 8.6615 2.83133V3.03381C8.6615 3.45324 8.49475 3.85566 8.19783 4.15257L7.69928 4.65113Z"
                fill="black"
              />
            </svg>
            <div className="whitespace-nowrap">
              {t('page_course.lessonDuration', { count: lesson.duration })}
            </div>
          </div>
          <div className="flex flex-row items-center gap-1 text-lg/[1] xs:text-2xl/[1]">
            <svg
              className="h-7 w-7 xs:h-9 xs:w-9"
              width="26"
              height="29"
              viewBox="0 0 26 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="12.9237" cy="15.58" r="12.58" fill="#E0FF88" />
              <path
                d="M6.87928 12.3942L10.7061 18.3278C11.357 19.3372 12.8572 19.2608 13.4023 18.1905L21.4417 2.40438"
                stroke="#171717"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
            <div>пройден</div>
          </div>
        </div>
      </div>
    </div>
  )
}
