import clsx from 'clsx'
import { SVGProps } from 'react'
import { SPRITES_META, type SpritesMap } from './sprite.gen'

// Merging all icons as `SPRITE_NAME/SPRITE_ICON_NAME`
export type IconName = {
  [Key in keyof SpritesMap]: `${Key}/${SpritesMap[Key]}`
}[keyof SpritesMap]

export interface IconProps
  extends Omit<SVGProps<SVGSVGElement>, 'name' | 'type'> {
  name: IconName
}

export function Icon({ name, className, ...props }: IconProps) {
  const [spriteName, iconName] = name.split('/') as [
    keyof SpritesMap,
    SpritesMap[keyof SpritesMap],
  ]
  const { filePath, items } = SPRITES_META[spriteName]
  const { viewBox, width, height } = items[iconName]
  const rect = width === height ? 'xy' : width > height ? 'x' : 'y'

  return (
    <svg
      // We recommend to use specific component class for avoid collisions with other styles and simple override it
      className={clsx('icon', className)}
      /**
       * this prop is used by the "icon" class to set the icon's scaled size
       * @see https://github.com/secundant/neodx/issues/92 - Issue with original motivation
       */
      data-icon-aspect-ratio={rect}
      viewBox={viewBox}
      focusable="false"
      aria-hidden
      {...props}
    >
      {/* For example, "/common.svg#favourite". Change base path if you don't store sprites under the root. */}
      <use href={`/${filePath}#${iconName}`} />
    </svg>
  )
}
