import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { modal } from 'shared/modal'
import { Icon } from 'shared/ui/icon'

type Props = {
  title: string
  subtitle: string
  children: JSX.Element
}

export default function AuthFormContainer({
  title,
  subtitle,
  children,
}: Props) {
  return (
    <div className="fixed inset-x-0 bottom-0 overflow-y-auto sm:inset-0 sm:mt-22">
      <div className="flex min-h-full items-center justify-end p-4 text-center sm:justify-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-oiqLightBlue-100 px-2 py-4 font-antonymMedium text-oiqDarkGray-900 shadow-xl transition-all xxs:p-6 sm:rounded-lg">
            <div className="-m-4 hidden flex-row justify-end sm:flex">
              <button
                type="button"
                className="flex h-5 w-5 items-center rounded-full focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
                onClick={() => modal.close()}
              >
                <Icon name="widgetAuthForm/closeButton" className="h-5 w-5" />
              </button>
            </div>
            <div className="flex flex-col items-center gap-1.5 p-2 xxs:p-4">
              <Dialog.Title className="font-formularNeutral text-2xl/[.9] tracking-tight xxs:text-3xl/[.9]">
                {title}
              </Dialog.Title>
              <div>{subtitle}</div>
            </div>
            {children}
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  )
}
