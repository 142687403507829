import dp from 'dompurify'
import { useTranslation } from 'react-i18next'
import { Footer } from 'widget/footer'
import { Header } from 'widget/header'

export default function PrivacyPolicy() {
  const { t } = useTranslation()

  return (
    <div>
      <Header />
      <div className="flex justify-center py-16">
        <div
          className="prose dark:prose-invert"
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(t('page_privacyPolicy.privacyPolicy')),
          }}
        />
      </div>
      <Footer />
    </div>
  )
}
