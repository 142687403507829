import dp from 'dompurify'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { Button } from 'shared/ui/button'
import { Checkbox } from 'shared/ui/checkbox'
import { Input } from 'shared/ui/input'
import {
  $email,
  $errorKey,
  $policyAccepted,
  $shakeButton,
  $showError,
  setEmail,
  setPolicyAccepted,
  subscribe,
} from './model'

export default function SubscribeToNewsletter() {
  return (
    <>
      <SubscribeDesktop />
      <SubscribeMobile />
    </>
  )
}

function SubscribeDesktop() {
  const { t } = useTranslation()
  const email = useUnit($email)
  const policyAccepted = useUnit($policyAccepted)
  const showError = useUnit($showError)
  const shakeButton = useUnit($shakeButton)
  const errorKey = useUnit($errorKey)

  const errorMessage = errorKey ? t(errorKey) : null

  return (
    <div className="m-auto mb-5 hidden h-auto w-11/12 max-w-6xl rounded-lg bg-[url('/src/4_features/subscribeToNewsletter/assets/background_light.svg')] bg-cover py-16 font-antonymMedium text-oiqDarkGray-900 sm:block dark:bg-[url('/src/4_features/subscribeToNewsletter/assets/background_dark.svg')]">
      <p
        className="mx-auto w-2/3 pb-12 text-center text-2xl/[1.1] -tracking-[.03em] lg:w-2/5"
        dangerouslySetInnerHTML={{
          __html: dp.sanitize(t('feature_subscribeToNewsletter.title')),
        }}
      />
      <div className="mx-auto flex w-2/3 flex-col items-start lg:w-3/5">
        <div className="flex h-11 max-h-20 w-full flex-row rounded-md text-lg/[1.1] -tracking-[0.01em] focus-within:outline-none focus-within:outline-offset-2 focus-within:outline-oiqLime-400 lg:h-12">
          <div className="relative w-full">
            <Input
              variant="subscribe"
              className={`${showError ? 'pr-7' : 'pr-4'}`}
              placeholder="your@email.here"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              className={`${showError ? 'absolute' : 'hidden'} right-5 top-3.5`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5 dark:text-white/[.92]"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
          <div className="relative -ml-3 h-full self-end">
            <Button
              variant="primary"
              className={`border-2  ${
                showError && shakeButton ? 'animate-shake' : ''
              }`}
              onClick={() => subscribe()}
            >
              {t('feature_subscribeToNewsletter.subscribeButton')}
            </Button>
          </div>
        </div>
        <div className={`${showError ? 'flex' : 'hidden'} mt-2`}>
          {errorMessage}
        </div>
        <div className="flex cursor-pointer flex-row pt-4">
          <div className="mr-2 flex self-baseline">
            <Checkbox
              id="policyAccepted"
              checked={policyAccepted}
              onCheckedChange={setPolicyAccepted}
            />
          </div>
          <label
            className="cursor-pointer select-none text-sm/[1.1] -tracking-[0.01em] lg:text-base/[1.1]"
            htmlFor="policyAccepted"
          >
            {t('feature_subscribeToNewsletter.policyLabel')}
          </label>
        </div>
      </div>
    </div>
  )
}

function SubscribeMobile() {
  const { t } = useTranslation()
  const email = useUnit($email)
  const policyAccepted = useUnit($policyAccepted)
  const showError = useUnit($showError)
  const shakeButton = useUnit($shakeButton)
  const errorKey = useUnit($errorKey)

  const errorMessage = errorKey ? t(errorKey) : null

  return (
    <div className="m-auto mb-5 h-auto w-11/12 rounded-lg bg-oiqLightBlue-200 bg-[url('/src/4_features/subscribeToNewsletter/assets/background_light_mobile.svg')] bg-cover py-14 font-antonymMedium text-oiqDarkGray-900 sm:hidden dark:bg-oiqLightGreen-400 dark:bg-[url('/src/4_features/subscribeToNewsletter/assets/background_dark_mobile.svg')]">
      <p
        className="mx-auto w-[90%] px-4 pb-14 text-center text-2xl/7 -tracking-[.03em]"
        dangerouslySetInnerHTML={{
          __html: dp.sanitize(t('feature_subscribeToNewsletter.title')),
        }}
      />
      <div className=" relative mx-6 flex flex-col">
        <Input
          variant="subscribe"
          className={`${showError ? 'pr-7' : 'pr-4'}`}
          placeholder="your@email.here"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={`${showError ? 'absolute' : 'hidden'} right-2 top-3`}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            className="h-6 w-6 dark:text-white/[.92]"
          >
            <path
              fillRule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className={`${showError ? 'flex' : 'hidden'} mt-2`}>
          {errorMessage}
        </div>
        <div className="mt-2">
          <Button
            variant="primary"
            className={`border-2 ${
              showError && shakeButton ? 'animate-shake' : ''
            }`}
            onClick={() => subscribe()}
          >
            {t('feature_subscribeToNewsletter.subscribeButton')}
          </Button>
        </div>

        <div className="mt-4 flex cursor-pointer flex-row">
          <div className="mr-2 flex self-baseline">
            <Checkbox
              checked={policyAccepted}
              onCheckedChange={setPolicyAccepted}
            />
          </div>
          <label
            className="cursor-pointer select-none text-base/[1.1] -tracking-[0.01em]"
            htmlFor="policyAccepted"
          >
            {t('feature_subscribeToNewsletter.policyLabel')}
          </label>
        </div>
      </div>
    </div>
  )
}
