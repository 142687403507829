import { Disclosure } from '@headlessui/react'
import { useTranslation } from 'react-i18next'
import { CoursePageQuery } from 'shared/gql'
import { LessonCardDesktop, LessonCardMobile } from './LessonCard'

type Props = {
  parts: CoursePageQuery['coursePage']['course']['parts']
}

export default function CourseParts({ parts }: Props) {
  const { t } = useTranslation()

  return (
    <div className="mb-5 font-formularNeutral text-oiqDarkGray-900 dark:text-oiqWhite-50/[.92]">
      {/* DESKTOP */}
      {parts.map((part, index) => (
        <div
          key={part.id}
          className="mx-auto my-2 hidden w-11/12 max-w-6xl flex-col justify-between rounded-lg bg-white px-20 py-8 sm:flex lg:px-30 dark:bg-oiqDarkGray-800"
        >
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-6 flex flex-row items-baseline justify-between gap-2 rounded focus-visible:outline-none focus-visible:outline-offset-4 focus-visible:outline-oiqLime-400">
                  <div className="flex flex-col justify-between">
                    <div className="text-left text-4xl/[0.9] tracking-tighter">
                      {part.title}
                    </div>
                    <div className="flex flex-row items-center font-antonymMedium">
                      <div className="text-3xl">часть {index + 1}</div>
                      <div className="ml-2 rounded-full bg-oiqLime-500 px-2 text-base/[1.5] italic text-oiqDarkGray-900">
                        {/* {t.lessonCount(part.lessons.length)} */}
                      </div>
                    </div>
                  </div>
                  <div>
                    <svg
                      className={`${open ? 'rotate-180 transform' : ''}`}
                      width="24"
                      height="24"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#171717"
                        className="dark:fill-white/[.92]"
                      />
                      <path
                        d="M26.5547 20.4453L17.7076 11.5982C17.3171 11.2077 16.6839 11.2077 16.2934 11.5982L7.44629 20.4453"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        className="dark:stroke-oiqDarkGray-900"
                      />
                    </svg>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="mb-6">
                  <div className="mt-6 w-4/5 font-antonymMedium text-lg/[1.2]">
                    {part.description}
                  </div>
                  <div className="mt-8 grid grid-cols-autoFill gap-x-4 gap-y-2">
                    {part.lessons.map((lesson) => (
                      <LessonCardDesktop key={lesson.id} lesson={lesson} />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}

      {/* MOBILE */}
      {parts.map((part, index) => (
        <div
          key={part.id}
          className="my-2 flex flex-col bg-white px-4 pb-10 pt-5 xs:my-3 xs:px-6 sm:hidden dark:bg-oiqDarkGray-800"
        >
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="mt-6 flex flex-row items-start justify-between gap-4">
                  <div className="flex flex-col justify-between">
                    <div className="text-left text-3xl/[0.9] tracking-tight xs:text-5xl/[1]">
                      {part.title}
                    </div>
                    <div className="flex flex-row items-center py-1 font-antonymMedium">
                      <div className="text-2xl xs:text-3xl">
                        часть {index + 1}
                      </div>
                      {/* <div className="ml-2 rounded-full bg-oiqLime-500 px-3 py-1 text-2xl/[1] italic text-oiqDarkGray-900 xs:text-3xl/[1]">
                        {t.lessonCount(part.lessons.length)}
                      </div> */}
                    </div>
                  </div>
                  <div>
                    <svg
                      className={`${
                        open ? 'rotate-180 transform' : ''
                      } h-6 w-6 xs:h-8 xs:w-8`}
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="17"
                        cy="17"
                        r="17"
                        fill="#171717"
                        className="dark:fill-white/[.92]"
                      />
                      <path
                        d="M26.5547 20.4453L17.7076 11.5982C17.3171 11.2077 16.6839 11.2077 16.2934 11.5982L7.44629 20.4453"
                        stroke="white"
                        strokeWidth="3"
                        strokeLinecap="round"
                        className="dark:stroke-oiqDarkGray-900"
                      />
                    </svg>
                  </div>
                </Disclosure.Button>
                <Disclosure.Panel className="">
                  <div className="my-6 font-antonymMedium text-base/[1.2] xs:text-xl/[1.2]">
                    {part.description}
                  </div>
                  <div className="flex flex-col">
                    {part.lessons.map((lesson) => (
                      <LessonCardMobile key={lesson.id} lesson={lesson} />
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      ))}
    </div>
  )
}
