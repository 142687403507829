import dp from 'dompurify'
import { EnrollToCourseButton } from 'feature/enrollToCourse'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { CoursePageQuery } from 'shared/gql'
import { useUnit } from 'effector-react'
import { viewer } from 'entity/viewer'

type Props = {
  course: CoursePageQuery['coursePage']['course']
  isAuthorized: boolean
}

export default function CourseAbstractMobile({ course, isAuthorized }: Props) {
  const { t } = useTranslation()

  const durationHours = Math.ceil(course.duration / 60)
  const duration =
    durationHours > 1
      ? t('page_course.duration', { count: durationHours })
      : t('page_course.durationHourOrLess')

  return (
    <>
      <div
        className="flex aspect-[1/1.4] flex-col justify-end bg-cover bg-center"
        style={{ backgroundImage: `url(${course.mobileImageUrl})` }}
      >
        <div
          className={`${
            course.titleHelperUnder ? 'hidden' : 'flex flex-row'
          } justify-center pb-2 font-antonymMedium text-sm xs:text-lg`}
        >
          <p className="mr-[1px] rounded-sm bg-oiqDarkGray-900 px-2 text-white/[.92]">
            {course.titleHelper}
          </p>
          {/* <p className="rounded-sm bg-white px-2 text-oiqDarkGray-900">
            Часть <b className="font-formularNeutral">1</b>
          </p> */}
        </div>
        <h1
          className={`${
            course.titleHelperUnder
              ? 'pb-2 text-3xl/[0.85] xs:text-5xl/[0.85]'
              : 'mb-8 text-5xl/[0.85] xs:text-7xl/[0.85]'
          } px-3 text-center font-formularNeutral font-bold -tracking-[0.06em] text-white/[.92] `}
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(course.title),
          }}
        />
        <div
          className={`${
            course.titleHelperUnder ? 'flex flex-row' : 'hidden'
          } mb-6 justify-center font-antonymMedium text-base`}
        >
          <p className="rounded-sm bg-oiqDarkGray-900 px-2 text-white/[.92]">
            {course.titleHelper}
          </p>
        </div>
      </div>

      <div className="flex flex-col bg-white px-4 py-5 text-oiqDarkGray-900 xs:px-6 dark:bg-oiqLightGreen-400">
        <div className="my-2 font-formularNeutral -tracking-[0.04em] xs:text-lg">
          {t('page_course.aboutTheCourse')}
        </div>
        <div className="py-3 text-2xl/[1.17] xs:text-3xl/[1.17]">
          {course.description}
        </div>
        <div className="mb-2 mt-4 font-formularNeutral text-base -tracking-[0.04em] xs:text-lg">
          {t('page_course.howWeLearn')}
        </div>
        <p className="mb-5 pt-3 font-antonymMedium text-lg/[1.2] xs:text-xl/[1.2]">
          {course.goal}
        </p>
        <div className="flex flex-wrap gap-1 border-b border-oiqGray-200 pb-7 pt-5 font-antonymMedium dark:border-oiqDarkGray-900">
          {course.tags.map((tag) => (
            <div
              key={tag}
              className="whitespace-nowrap rounded-full bg-oiqGray-200 px-3 text-base/[2] text-oiqDarkGray-900 xs:text-lg/[2] dark:bg-white"
            >
              {tag}
            </div>
          ))}
        </div>

        <div className="flex flex-row items-center justify-between py-5">
          {course.authors.map((author) => (
            <Fragment key={author.id}>
              <div className="flex flex-col justify-center pl-1">
                <p className="font-antonymMedium text-base/[1.87] xs:text-lg/[1.87]">
                  {t('page_course.author')}
                </p>
                <p className="text-2xl/[1.07] tracking-tighter xs:text-3xl/[1.07]">
                  {author.name}
                </p>
              </div>
              <img
                src={author.avatarUrl}
                alt={author.name}
                className="h-auto w-1/3 xs:w-1/4"
              />
            </Fragment>
          ))}
        </div>
        <div className="border-t border-oiqGray-200 py-7 dark:border-oiqDarkGray-900">
          <div className="font-antonymMedium text-base/[0.9] xs:text-lg/[0.9]">
            {t('page_course.abstractDuration')}
          </div>
          <div className="pt-5 font-formularNeutral text-2xl/[1] tracking-tighter xs:text-3xl/[1] ">
            {duration}
          </div>
        </div>
        {isAuthorized && (
          <div className="border-t border-oiqGray-200 py-7 dark:border-oiqDarkGray-900">
            <EnrollToCourseButton
              courseId={course.id}
              courseState={course.state}
            />
          </div>
        )}
      </div>
    </>
  )
}
