import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { modal } from 'shared/modal'
import { Icon } from 'shared/ui/icon'

type Props = {
  children: JSX.Element
}

export default function SubscribeModalContainer({ children }: Props) {
  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="mx-auto flex min-h-full max-w-6xl items-center justify-center font-antonymMedium">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <Dialog.Panel className="flex aspect-[5/4] w-11/12 transform flex-col items-center overflow-hidden rounded-lg bg-white py-4 shadow-xl transition-all sm:w-1/2 lg:w-2/5">
            <div className="flex w-full flex-row justify-end px-4">
              <button
                className="flex h-8 w-8 items-center rounded-full focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
                onClick={() => modal.close()}
              >
                <Icon className="h-8 w-8" name="sharedModal/close" />
              </button>
            </div>
            <div className="mx-auto flex h-full w-2/3 flex-col items-center justify-center">
              {children}
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  )
}
