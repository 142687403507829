import { useUnit } from 'effector-react'
import { CourseCard, CourseCardLoader } from 'entity/course'
import { useTranslation } from 'react-i18next'
import { Footer } from 'widget/footer'
import { Header } from 'widget/header'
import { fetchCoursesPage } from './model'
import NoCourses from './ui/NoCourses'

export default function CoursesPage() {
  const { t } = useTranslation()
  const finished = useUnit(fetchCoursesPage.$finished)
  const { data } = useUnit(fetchCoursesPage)
  const noCourses = finished && !data?.courseCards.length

  return (
    <div>
      <Header />
      <div className="mx-auto max-w-7xl px-3 pb-5 pt-6 md:px-10 lg:px-12 lg:pt-12">
        <h3 className="mb-2 text-5xl font-semibold leading-extraTight text-oiqWhite-50 xs:text-6xl sm:text-7xl sm:leading-none">
          {t('page_courses.title')}
        </h3>
        <div className="mt-10 md:mt-12 lg:mt-16">
          <div className="grid flex-1 grid-cols-autoFillMobile gap-1 sm:grid-cols-autoFill">
            <Cards />
          </div>
          {noCourses && <NoCourses />}
        </div>
      </div>
      <Footer />
    </div>
  )
}

function Cards() {
  const { data, pending } = useUnit(fetchCoursesPage)

  if (pending) {
    return <CardsLoader />
  }

  return data?.courseCards.map((card) => (
    <CourseCard key={card.slug} card={card} />
  ))
}

function CardsLoader() {
  const loadingCards = ['0', '1', '2', '3']

  return loadingCards.map((id) => (
    <CourseCardLoader
      key={id}
      className="w-full rounded-md bg-white dark:bg-oiqDarkGray-800"
    />
  ))
}
