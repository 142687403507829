import {
  cache,
  createQuery,
  keepFresh,
  localStorageCache,
} from '@farfetched/core'
import { createEvent } from 'effector'
import { sdk } from 'shared/api'

export const refetch = createEvent()

export const fetchHeaderWidget = createQuery({
  handler: async () => {
    const data = await sdk.HeaderWidget()
    return data.headerWidget
  },
})

cache(fetchHeaderWidget, { adapter: localStorageCache() })
keepFresh(fetchHeaderWidget, { automatically: true, triggers: [refetch] })

fetchHeaderWidget.start()
