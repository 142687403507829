import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

export default function CourseAbstractLoaderMobile() {
  const theme = useUnit($theme)

  return (
    <>
      {/* Big image with title */}
      <ContentLoader
        className="bg-white bg-gradient-to-br from-oiqLightBlue-200 to-[#F6F9FC] dark:from-[#202020] dark:to-[#454545]"
        speed={2}
        viewBox="0 0 375 530"
        backgroundColor={theme === 'dark' ? '#202020' : '#C6D2DD'}
        foregroundColor={theme === 'dark' ? '#5E5E5E' : '#F6F9FC'}
      >
        <rect x="60" y="370" rx="3" ry="3" width="255" height="23" />
        <rect x="20" y="400" rx="3" ry="3" width="335" height="40" />
        <rect x="20" y="445" rx="3" ry="3" width="335" height="40" />
      </ContentLoader>

      {/* Abstract */}
      <ContentLoader
        className="border-b bg-white dark:bg-oiqLightGreen-400"
        speed={2}
        viewBox="0 0 375 860"
        backgroundColor={theme === 'dark' ? '#5C5C4B' : '#C6D2DD'}
        foregroundColor={theme === 'dark' ? '#88886d' : '#F6F9FC'}
      >
        <rect x="15" y="30" rx="9" ry="9" width="100" height="18" />
        <rect x="15" y="68" rx="5" ry="5" width="335" height="177" />
        <rect x="15" y="275" rx="9" ry="9" width="100" height="18" />
        <rect x="15" y="313" rx="5" ry="5" width="335" height="120" />

        <rect x="15" y="485" rx="15" ry="15" width="140" height="30" />
        <rect x="160" y="485" rx="15" ry="15" width="140" height="30" />
        <rect x="15" y="520" rx="15" ry="15" width="140" height="30" />

        <rect x="15" y="580" rx="0" ry="0" width="345" height="1" />
        <rect x="15" y="610" rx="9" ry="9" width="300" height="18" />
        <rect x="15" y="638" rx="9" ry="9" width="100" height="30" />
        <rect x="15" y="698" rx="0" ry="0" width="345" height="1" />

        <rect x="15" y="748" rx="9" ry="9" width="100" height="18" />
        <rect x="15" y="771" rx="10" ry="10" width="100" height="30" />
        <circle cx="300" cy="770" r="55" />
      </ContentLoader>
    </>
  )
}
