import dp from 'dompurify'
import { useTranslation } from 'react-i18next'

export default function NoCourses() {
  const { t } = useTranslation()

  return (
    <div className="mx-auto flex max-w-[420px] items-center justify-center py-8 text-center md:py-12">
      <div>
        <h2
          className="mb-2 text-3xl font-semibold leading-extraSnug md:text-4xl md:leading-extraTight"
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(t('page_courses.noCoursesTitle')),
          }}
        />
        <p>{t('page_courses.noCoursesDescription')}</p>
      </div>
    </div>
  )
}
