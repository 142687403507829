import {
  cache,
  createQuery,
  keepFresh,
  localStorageCache,
} from '@farfetched/core'
import { createEvent } from 'effector'
import { sdk } from 'shared/api'

export const toggleMobileMenu = createEvent()
export const refetch = createEvent()

export const fetchMobileMenuWidget = createQuery({
  handler: async () => {
    const data = await sdk.MobileMenuWidget()
    return data.mobileMenuWidget
  },
})

cache(fetchMobileMenuWidget, { adapter: localStorageCache() })
keepFresh(fetchMobileMenuWidget, { automatically: true, triggers: [refetch] })

fetchMobileMenuWidget.start()
