import { Listbox, Transition } from '@headlessui/react'
import { useUnit } from 'effector-react'
import { Fragment } from 'react'
import { $locale, locales } from 'shared/i18n'
// import { setLocale } from './model'

export function LanguageSwitch() {
  const locale = useUnit($locale)

  return (
    <Listbox value={locale} as="div">
      {/* onChange={setLocale} */}
      <div className="relative">
        <Listbox.Button className="relative w-18 cursor-pointer rounded-full bg-oiqDarkGray-900 py-2 pl-3 pr-8 text-left uppercase text-white focus:outline-none focus-visible:ring-1 focus-visible:ring-oiqLime-400 focus-visible:ring-offset-2 focus-visible:ring-offset-transparent sm:w-16 sm:text-sm dark:bg-white dark:text-oiqDarkGray-900">
          <span className="block">
            {locales.find((l) => l.value === locale.value)?.label}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute mx-2 mt-1 flex max-h-60 w-14 flex-col overflow-scroll rounded-lg bg-white py-1 text-center text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:mx-1 sm:text-sm">
            {locales.map((l) => (
              <Listbox.Option
                key={l.label}
                className={({ active }) =>
                  `relative cursor-default select-none py-1 uppercase  ${
                    active
                      ? 'bg-oiqLime-500 dark:text-oiqDarkGray-900'
                      : 'text-oiqDarkGray-900'
                  }`
                }
                // onClick={() => setLocale(l.value)}
                value={l.value}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected
                          ? 'bg-oiqDarkGray-900 py-1 text-white'
                          : 'font-normal'
                      }`}
                    >
                      {l.label}
                    </span>
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}
