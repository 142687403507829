import { Disclosure } from '@headlessui/react'
import { useState } from 'react'
// import { Progress } from 'shared/progress'
import { Header } from 'widget/header'
import AsideContentLoader from './ui/AsideContentLoader'
import AsideHeaderLoader from './ui/AsideHeaderLoader'
import LessonContentLoader from './ui/LessonContentLoader'
import LessonMobileLoader from './ui/LessonMobileLoader'
import LessonVideoLoader from './ui/LessonVideoLoader'
import MobileMenuContentLoader from './ui/MobileMenuContentLoader'
import MobileMenuHeaderLoader from './ui/MobileMenuHeaderLoader'
// import { $lessonPage, $t } from './model'
import { useUnit } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { Icon } from 'shared/ui/icon'

export default function LessonPage() {
  const [openAside, setOpenAside] = useState(true)
  const [openDisclosure] = useState(true)
  const { t } = useTranslation()
  // const { data, loading } = useUnit($lessonPage)
  // const lesson = data?.lesson
  // const courseCard = data?.courseCard

  return (
    <div className="min-h-screen">
      <Header />

      {/* DESKTOP */}
      <div className="mx-auto mt-6 hidden max-w-6xl font-formularNeutral sm:block">
        <div className="relative mx-auto">
          {/* {loading ? ( */}
          <aside className="absolute">
            <AsideHeaderLoader className="mb-4 ml-4 w-68 rounded-md bg-white lg:w-76 dark:bg-oiqDarkGray-800" />
            <AsideContentLoader className="mb-4 ml-4 w-68 rounded-md bg-white lg:w-76 dark:bg-oiqDarkGray-800" />
          </aside>
          {/* ) : (
            <aside
              className={`${
                openAside ? 'translate-x-0' : 'translate-x-[calc(-100%+40px)]'
              } absolute left-4 flex h-3/4 w-68 flex-col gap-4 overflow-auto rounded-md duration-200 lg:w-76`}
            >
              <header className="sticky top-0 flex flex-col justify-between rounded-md bg-white p-2 dark:bg-oiqDarkGray-800">
                <div className="flex flex-row gap-2.5">
                  <div className="aspect-video w-1/3 rounded-md">
                    <img src={courseCard?.imageUrl} alt="" />
                  </div>
                  <div className="flex w-2/3 flex-col justify-evenly gap-1">
                    <div className="mr-2 flex flex-row justify-between">
                      <div className="inline-flex items-center">
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 28 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.22852 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H25C26.6569 24 28 22.6569 28 21V5.41787C28 3.76102 26.6569 2.41787 25 2.41787H13.4802C12.8394 2.41787 12.2154 2.21266 11.6996 1.83229L10.0091 0.585579C9.49337 0.205213 8.86936 0 8.22852 0Z"
                            fill="#171717"
                            className="dark:fill-white"
                          />
                        </svg>
                        <span className="ml-1.5 font-antonymMedium text-xs/[.85] tracking-tight">
                          {t.type}
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden text-ellipsis whitespace-nowrap py-0.5 text-base/[1] tracking-tight">
                      {courseCard?.title}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <Progress
                    progress={(3 / 25) * 100}
                    label={`${t.progress} 3 / ${t.lessonCount(25)}`}
                  />
                </div>
              </header>
              <div
                className={`${
                  openAside ? 'visible' : 'invisible'
                } overflow-scroll rounded-md bg-white dark:bg-oiqDarkGray-800`}
              >
                <Disclosure defaultOpen={openDisclosure}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="group fixed flex w-full flex-row items-center justify-between rounded-md bg-white p-5 font-antonymMedium text-base dark:bg-oiqDarkGray-800">
                        <div className="inline-flex gap-2">
                          <div className="">{t.part} 1</div>
                          <div className="rounded-full bg-oiqLime-500 px-2 italic text-oiqDarkGray-900">
                            {t.lessonCount(5)}
                          </div>
                        </div>
                        <button className="rounded-full p-0.5 group-focus:outline-none group-focus:ring-2 group-focus:ring-oiqLime-400 group-focus:ring-offset-1 group-focus:ring-offset-transparent">
                          <svg
                            className={`${open ? 'rotate-180 transform' : ''} `}
                            width="22"
                            height="22"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              cx="17"
                              cy="17"
                              r="17"
                              fill="#171717"
                              className="dark:fill-white"
                            />
                            <path
                              d="M26.5547 20.4453L17.7076 11.5982C17.3171 11.2077 16.6839 11.2077 16.2934 11.5982L7.44629 20.4453"
                              stroke="white"
                              stroke-width="3"
                              stroke-linecap="round"
                              className="dark:stroke-oiqDarkGray-900"
                            />
                          </svg>
                        </button>
                      </Disclosure.Button>
                      <Disclosure.Panel>
                        <nav className="mt-10 flex flex-col rounded-b-md bg-white p-5 dark:bg-oiqDarkGray-800">
                          <LessonNavigation
                            title='"Hello, world" это просто, да?'
                            duration={15}
                            completed
                            href=""
                          />
                          <LessonNavigation
                            title="Как процессоры читают машинный код"
                            duration={27}
                            completed
                            href=""
                          />
                          <LessonNavigation
                            title="Язык ассемблера против машинного кода"
                            duration={10}
                            href=""
                          />
                          <LessonNavigation
                            title="Подключаем ЖК-дисплей к нашему компьютеру"
                            duration={12}
                            href=""
                          />
                          <LessonNavigation
                            title="Что такое стеки как он работает"
                            duration={7}
                            href=""
                          />
                        </nav>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            </aside>
          )} */}
          <main
            className="ml-72 pb-5 lg:ml-80"
            // {`${
            //   openAside ? 'ml-10' : 'ml-15 transition-[margin-left]'
            // } ml-76 pb-5 duration-200`}
          >
            <div className="flex flex-col gap-2 px-4 lg:px-6">
              {/* {loading ? (
                <> */}
              <LessonVideoLoader className="rounded-md bg-gradient-to-br from-oiqLightBlue-200 to-[#F6F9FC] dark:from-[#202020] dark:to-[#454545]" />
              <LessonContentLoader className="rounded-md bg-white dark:bg-oiqDarkGray-800" />
              <LessonContentLoader className="rounded-md bg-white dark:bg-oiqDarkGray-800" />
              {/* </>
              ) : (
                <>
                  <div className="aspect-video rounded-md">
                    <iframe
                      src="https://www.youtube.com/embed/HeAHsr47kJI"
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      className=" inset-0 flex h-full w-full items-center justify-center overflow-hidden rounded-md"
                    />
                  </div>
                  <div className="flex flex-col rounded-md bg-white px-10 py-6 dark:bg-oiqDarkGray-800">
                    <h2 className="text-2xl tracking-tight">
                      Welcome to the first part of our Lift-off series!
                    </h2>
                    <div className="mt-4 whitespace-pre-line font-antonymMedium text-base/[1.2]">
                      Here we begin our journey building a full-stack GraphQL
                      example application called
                      <strong> Catstronauts</strong>, a learning platform for
                      adventurous cats who want to explore the universe!
                      <span>😺</span>&nbsp;<span>🚀</span>
                      <br />
                      <br />
                      The first feature we'll build for the app will fetch a (
                      <i>mocked</i>) list of learning tracks from a GraphQL API
                      and display them in a card grid on our homepage.
                    </div>
                  </div>
                  <div className="flex flex-col rounded-md bg-white px-10 py-6 dark:bg-oiqDarkGray-800">
                    <div className="relative bg-oiqLightBlue-100 p-5 dark:bg-oiqDarkGray-900">
                      <button className="absolute right-2 top-2 rounded-full bg-white px-1.5 py-0.5 align-middle text-xs tracking-tight text-[#AAAAAA]">
                        copy
                      </button>
                      <code className="font-antonymMedium">Layout grid</code>
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </main>
        </div>
      </div>

      {/* MOBILE */}
      <div className="flex flex-col font-formularNeutral sm:hidden">
        <Disclosure defaultOpen={openDisclosure}>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full flex-row items-center justify-between border-b border-oiqLightGreen-400 bg-white px-5 py-2 dark:bg-oiqDarkGray-800">
                <span></span>
                <span className="font-antonymMedium xs:text-xl">
                  {t('page_lesson.lessonMobileMenu')}
                </span>
                <Icon
                  className={`${
                    open ? 'rotate-180 transform' : ''
                  } h-6 w-5 xs:h-7 xs:w-6`}
                  name={'pageLesson/lessonMobileMenuButton'}
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                {/* {loading ? ( */}
                <MobileMenuHeaderLoader className="bg-white dark:bg-oiqDarkGray-800" />
                {/* ) : (
                  <header className="flex w-full flex-col justify-evenly bg-white p-2 dark:bg-oiqDarkGray-800 ">
                    <div className="flex flex-row gap-2.5">
                      <div className="aspect-video w-2/5 rounded-md">
                        <img src={courseCard?.imageUrl} alt="" />
                      </div>
                      <div className="flex w-2/3 flex-col justify-evenly gap-1">
                        <div className="mr-2 flex flex-row justify-between">
                          <div className="inline-flex items-center">
                            <svg
                              className="h-6 w-7 sm:h-4 sm:w-5"
                              viewBox="0 0 28 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.22852 0H3C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H25C26.6569 24 28 22.6569 28 21V5.41787C28 3.76102 26.6569 2.41787 25 2.41787H13.4802C12.8394 2.41787 12.2154 2.21266 11.6996 1.83229L10.0091 0.585579C9.49337 0.205213 8.86936 0 8.22852 0Z"
                                fill="#171717"
                                className="dark:fill-white"
                              />
                            </svg>
                            <span className="ml-1.5 font-antonymMedium text-base/[.85] tracking-tight xs:text-lg/[.85]">
                              {t('page_lesson.lessonType')}
                            </span>
                          </div>
                        </div>
                        <div className="overflow-hidden text-ellipsis whitespace-nowrap py-0.5 text-xl/[1] tracking-tight xs:text-3xl/[1]">
                          {courseCard?.title}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <Progress
                        progress={(3 / 25) * 100}
                        label={`${t.progress} 3 / ${t.lessonCount(25)}`}
                      />
                    </div>
                  </header>
                )} */}

                {/* {loading ? ( */}
                <MobileMenuContentLoader className="my-3 bg-white dark:bg-oiqDarkGray-800" />
                {/* ) : (
                  <div className="my-3 overflow-scroll bg-white dark:bg-oiqDarkGray-800">
                    <div className="flex w-full flex-row items-center justify-between px-5 pb-3 pt-6 font-antonymMedium text-base">
                      <div className="inline-flex items-baseline gap-2">
                        <div className="text-2xl sm:text-base">часть 1</div>
                        <div className="rounded-full bg-oiqLime-500 px-3 py-px text-xl italic text-oiqDarkGray-900 sm:px-2 sm:text-base">
                          5 уроков
                        </div>
                      </div>
                    </div>
                    <nav className="flex flex-col bg-white px-5 dark:bg-oiqDarkGray-800">
                      <LessonNavigation
                        title='"Hello, world"  это просто, да?'
                        duration={15}
                        completed
                        href=""
                      />
                      <LessonNavigation
                        title="Как процессоры читают машинный код"
                        duration={27}
                        completed
                        href=""
                      />
                      <LessonNavigation
                        title="Язык ассемблера против машинного кода"
                        duration={10}
                        href=""
                      />
                      <LessonNavigation
                        title="Подключаем ЖК-дисплей к нашему компьютеру"
                        duration={12}
                        href=""
                      />
                      <LessonNavigation
                        title="Что такое стеки как он работает"
                        duration={7}
                        href=""
                      />
                    </nav>
                  </div>
                )} */}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="flex flex-col gap-3">
          {/* {loading ? (
            <> */}
          <LessonMobileLoader className="bg-white dark:bg-oiqDarkGray-800" />
          <LessonMobileLoader className="bg-white dark:bg-oiqDarkGray-800" />
          {/* </>
          ) : (
            <>
              <div className="aspect-video">
                <iframe
                  src="https://www.youtube.com/embed/HeAHsr47kJI"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  className="flex h-full w-full items-center justify-center overflow-hidden"
                />
              </div>
              <div className="flex flex-col bg-white p-5 dark:bg-oiqDarkGray-800">
                <h2 className="text-2xl/[1.15] tracking-tight">
                  Welcome to the first part of our Lift-off series!
                </h2>
                <div className="mt-4 whitespace-pre-line font-antonymMedium text-base/[1.2]">
                  Here we begin our journey building a full-stack GraphQL
                  example application called
                  <strong> Catstronauts</strong>, a learning platform for
                  adventurous cats who want to explore the universe!
                  <span>😺</span>&nbsp;<span>🚀</span>
                  <br />
                  <br />
                  The first feature we'll build for the app will fetch a (
                  <i>mocked</i>) list of learning tracks from a GraphQL API and
                  display them in a card grid on our homepage.
                </div>
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  )
}

type navProps = {
  title: string
  duration: number
  completed?: boolean
  href: string
}
export function LessonNavigation({
  title,
  duration,
  completed,
  href,
}: navProps) {
  const { t } = useTranslation()

  return (
    <a
      className="flex flex-col gap-3 border-b border-oiqLightGreen-400 py-4 last:border-none sm:gap-1.5"
      href={href}
    >
      <div className="text-lg/[1] tracking-tight xs:text-xl/[1] sm:text-sm/[1]">
        {title}
      </div>
      <div className="flex flex-row items-center gap-3 font-antonymMedium tracking-tight">
        <div className="flex flex-row items-center gap-1 rounded-full bg-oiqGray-200 px-2 sm:py-px">
          <svg
            className="h-3 w-3 sm:h-2 sm:w-2"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.79932 4.75407C9.25534 4.29806 9.51227 3.6787 9.51227 3.03381V2.83133C9.51227 2.56163 9.73092 2.34214 10.0015 2.34214H11.1449V0.382812H0.375V2.34214H1.48696C1.75665 2.34214 1.97615 2.56078 1.97615 2.83133C1.97615 3.60638 2.28413 4.34995 2.83202 4.89785L3.16468 5.2305C3.34589 5.41171 3.465 5.65163 3.47606 5.90856C3.48797 6.18847 3.38417 6.45306 3.18765 6.64958L2.62359 7.2145C2.16417 7.67391 1.91064 8.28476 1.91064 8.9356V9.13809C1.91064 9.40778 1.69199 9.62728 1.42145 9.62728H0.375V11.5866H11.144V9.62728H10.0661C9.79643 9.62728 9.57693 9.40863 9.57693 9.13809V8.9356C9.57693 8.28987 9.32085 7.67136 8.86398 7.2145L8.32204 6.67256C8.14083 6.49134 8.02172 6.25057 8.01066 5.99449C7.99875 5.71459 8.10254 5.45 8.29907 5.25347L8.79762 4.75492L8.79932 4.75407ZM7.69928 4.65113C7.32579 5.02461 7.13267 5.53167 7.16415 6.06425C7.19137 6.52452 7.40321 6.95416 7.72906 7.28L8.26419 7.81514C8.56111 8.11206 8.72786 8.51447 8.72786 8.93475V9.13724C8.72786 9.40693 8.50921 9.62643 8.23867 9.62643H3.24975C2.98006 9.62643 2.76056 9.40778 2.76056 9.13724V8.93475C2.76056 8.51192 2.92476 8.11461 3.22423 7.81599L3.75936 7.28086C4.08521 6.95501 4.2962 6.52537 4.32342 6.06511C4.3549 5.53252 4.16178 5.02546 3.78829 4.65198L3.28974 4.15343C2.99282 3.85651 2.82607 3.45409 2.82607 3.03381V2.83133C2.82607 2.56163 3.04472 2.34214 3.31526 2.34214H8.17231C8.442 2.34214 8.6615 2.56078 8.6615 2.83133V3.03381C8.6615 3.45324 8.49475 3.85566 8.19783 4.15257L7.69928 4.65113Z"
              fill="black"
            />
          </svg>
          <span className="text-oiqDarkGray-900 xs:text-lg sm:text-xs">
            <b>{duration}</b> {t('page_lesson.timing')}
          </span>
        </div>
        <div
          className={`${
            completed ? 'inline-flex' : 'hidden'
          } items-center gap-1`}
        >
          <svg
            className="h-[27px] w-6 sm:h-5.5 sm:w-5"
            viewBox="0 0 26 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12.58" cy="15.4618" r="12.58" fill="#E0FF88" />
            <path
              d="M6.53553 12.276L10.3623 18.2097C11.0133 19.219 12.5135 19.1426 13.0585 18.0724L21.098 2.28622"
              stroke="#171717"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
          <span className="text-lg xs:text-xl sm:text-sm">
            {t('page_lesson.completed')}
          </span>
        </div>
      </div>
    </a>
  )
}
