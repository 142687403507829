import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from 'shared/utils'

const buttonVariants = cva(
  'h-full w-full rounded-lg text-oiqDarkGray-900 whitespace-nowrap border-oiqDarkGray-900 p-2 px-3 font-antonymMedium text-xl focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 sm:rounded-md sm:text-base',
  {
    variants: {
      variant: {
        primary: 'bg-oiqLime-500 hover:bg-oiqLime-400 border text-center',
        secondary:
          'bg-transparent border-2 sm:text-sm/[1] dark:border-white dark:text-white text-center',
        tertiary: 'bg-oiqLightGreen-400 text-center',
        ghost: 'bg-transparent border',
      },
      size: {
        default: '',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
