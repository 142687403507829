import { Link } from 'atomic-router-react'
import { useUnit } from 'effector-react'
import { CourseCard, CourseCardLoader } from 'entity/course'
import { useTranslation } from 'react-i18next'
import { CourseState } from 'shared/gql'
import { coursesRoute, homeRoute } from 'shared/routes'
import { Icon } from 'shared/ui/icon'
import { Footer } from 'widget/footer'
import { Header } from 'widget/header'
import { fetchProfilePage } from './model'
import { CourseProgressCard } from './ui/CourseProgressCard'

export default function ProfilePage() {
  const { t } = useTranslation()
  const { data, pending } = useUnit(fetchProfilePage)

  if (pending) {
    return null
  }

  const viewer = data?.viewer
  if (!viewer) {
    homeRoute.open()
    return null
  }

  const showRecommendations = (data?.recommendedCourseCards.length ?? 0) > 0

  const userCourseCards = data?.userCourseCards ?? []

  const hasInProgress =
    userCourseCards.filter(
      (card) => card.courseState === CourseState.InProgress
    ).length > 0
  const hasCompleted =
    userCourseCards.filter((card) => card.courseState === CourseState.Completed)
      .length > 0

  return (
    <div>
      <Header />
      {/* DESKTOP */}
      <div className="mx-auto hidden w-11/12 max-w-6xl pt-12 sm:block">
        <div className="border-b border-oiqDarkGray-900 pb-4 dark:border-oiqLightGreen-400">
          <div className="flex flex-row items-center justify-between gap-2">
            <div className="text-7xl/[0.8] font-bold tracking-tighter text-white/[.92]">
              {t('page_profile.greeting')}
              <img
                src={viewer.avatarUrl}
                alt={`Avatar of ${viewer.name}`}
                className="ml-2 inline w-24 rounded-full"
              />
              {viewer.name}
            </div>
            <div>
              {/* <Button
                variant="secondary"
                dangerouslySetInnerHTML={{
                  __html: dp.sanitize(t('page_profile.editProfileDesktop')),
                }}
              /> */}
            </div>
          </div>
          <div className="w-1/2 py-4 text-xl/[1.2] tracking-tight">
            {t('page_profile.abstract')}
          </div>
        </div>
      </div>
      {/* MOBILE */}
      <div className="mx-auto px-3 sm:hidden">
        <div className="flex flex-wrap items-end text-5xl/[0.9] font-bold -tracking-[0.07em] text-white/[.92] xs:text-7xl/[0.9]">
          {t('page_profile.greeting')}
          <img
            src={viewer.avatarUrl}
            alt=""
            className="inline w-20 rounded-full xs:w-26"
          />
          {viewer.name}
        </div>
        <div className="py-5 text-xl/[1.2] tracking-tight">
          {t('page_profile.abstract')}
        </div>
        {/* <div className="flex justify-center border-b border-oiqDarkGray-900 pb-7 dark:border-oiqLightGreen-400">
          <Button variant="secondary">
            {t('page_profile.editProfileDesktop')}
          </Button>
        </div> */}
      </div>

      {/* IN PROGRESS & COMPLETED courses */}
      {hasInProgress && (
        <div className="mx-auto my-8 px-3 xs:my-10 sm:w-11/12 sm:max-w-6xl sm:px-0">
          <div className="text-center font-antonymMedium text-4xl/[0.8] sm:text-left">
            {t('page_profile.coursesInProgress')}
          </div>
          <div className="mt-8 flex flex-col gap-3 xs:mt-10">
            {pending ? (
              <>
                {/* <CourseProgressLoader className="hidden rounded-lg bg-white dark:bg-oiqDarkGray-800 sm:block" />
            <CourseProgressLoaderMobile className="rounded-md bg-white dark:bg-oiqDarkGray-800 sm:hidden " /> */}
              </>
            ) : (
              (data?.userCourseCards ?? []).map((card) => (
                <CourseProgressCard key={card.slug} card={card} />
              ))
            )}
          </div>
        </div>
      )}

      {hasCompleted && (
        <div className="mx-auto my-8 px-3 xs:my-10 sm:w-11/12 sm:max-w-6xl sm:px-0">
          <div className="mt-8 text-center font-antonymMedium text-4xl/[0.8] xs:my-10 sm:text-left">
            {t('page_profile.coursesCompleted')}
          </div>
          <div className="mt-6 flex flex-col gap-2">{/* todo */}</div>
        </div>
      )}

      {/* CHOOSE SOMETHING CARDS*/}
      {showRecommendations && (
        <div className="mx-auto my-8 flex w-11/12 max-w-6xl flex-col items-center">
          <div className="py-8 text-center font-antonymMedium text-4xl/[0.8]">
            {t('page_profile.recommendations')}
          </div>
          <div className="grid w-full grid-cols-autoFillMobile gap-1 sm:grid-cols-autoFill">
            {pending ? (
              <CardsLoader />
            ) : (
              data?.recommendedCourseCards.map((card) => (
                <CourseCard key={card.slug} card={card} />
              ))
            )}
          </div>
          <Link
            className="mt-10 flex cursor-pointer flex-col items-center gap-1 rounded text-3xl text-white duration-200 hover:underline focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 sm:text-xl"
            to={coursesRoute}
          >
            {t('page_profile.ctaGoToCourses')}
            <br />
            <Icon name="pageProfile/whiteArrow" className="h-5 w-5" />
          </Link>
        </div>
      )}
      <Footer />
    </div>
  )
}

function CardsLoader() {
  const loadingCards = ['0', '1', '2', '3']

  return loadingCards.map((id) => (
    <CourseCardLoader
      key={id}
      className="w-full rounded-md bg-white dark:bg-oiqDarkGray-800"
    />
  ))
}
