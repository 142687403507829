import dp from 'dompurify'
import { EnrollToCourseButton } from 'feature/enrollToCourse'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { CoursePageQuery } from 'shared/gql'

type Props = {
  course: CoursePageQuery['coursePage']['course']
  isAuthorized: boolean
}

export default function CourseAbstract({ course, isAuthorized }: Props) {
  const { t } = useTranslation()

  const durationHours = Math.ceil(course.duration / 60)
  const duration =
    durationHours > 1
      ? t('page_course.duration', { count: durationHours })
      : t('page_course.durationHourOrLess')

  return (
    <>
      <div
        className="flex aspect-[2/1] flex-col justify-end rounded-t-lg bg-cover"
        style={{ backgroundImage: `url(${course.desktopImageUrl})` }}
      >
        <div
          className={`${
            course.titleHelperUnder ? 'hidden' : 'flex flex-row'
          } justify-center font-antonymMedium`}
        >
          <p className="mr-[1px] rounded-sm bg-oiqDarkGray-900 px-2 text-white/[.92]">
            {course.titleHelper}
          </p>
          {/* <p className="text-oiqDarokGray-900 rounded-sm bg-white px-2">
            Часть <b className="font-formularNeutral">1</b>
          </p> */}
        </div>
        <h1
          className={`${
            course.titleHelperUnder ? '' : 'mb-8'
          } text-center font-formularNeutral text-[8vw]/[1] font-bold -tracking-[0.08em] text-white/[.92] lg:text-[5.2rem]/[1]`}
          dangerouslySetInnerHTML={{
            __html: dp.sanitize(course.title ?? ''),
          }}
        />
        <div
          className={`${
            course.titleHelperUnder ? 'flex flex-row' : 'hidden'
          } mb-6 justify-center font-antonymMedium`}
        >
          <p className="rounded-sm bg-oiqDarkGray-900 px-2 text-white/[.92]">
            {course.titleHelper}
          </p>
        </div>
      </div>
      <div className="rounded-b-lg bg-white dark:bg-oiqLightGreen-400">
        <div className="grid grid-cols-8 gap-y-4 px-20 pb-20 pt-10 lg:px-30">
          <div className="col-span-2 row-start-1 font-formularNeutral text-sm/[0.9] -tracking-[0.04em]">
            {t('page_course.aboutTheCourse')}
          </div>
          <div className="col-start-1 col-end-6">
            <div className="row-start-2">
              <p className="text-xl/[1.13]">{course.description}</p>
              <div className="mt-8 text-sm/[0.9] -tracking-[0.04em]">
                {t('page_course.howWeLearn')}
              </div>
              <p className="mt-4 font-antonymMedium text-lg/[1.2]">
                {course.goal}
              </p>
            </div>
          </div>

          <div className="col-start-7 col-end-9 row-start-2 flex flex-col justify-between">
            <div className="flex flex-row">
              {course.authors.map((author) => (
                <Fragment key={author.id}>
                  <img
                    src={author.avatarUrl}
                    alt={author.name}
                    className="h-auto w-13 object-contain"
                  />
                  <div className="flex flex-col justify-center pl-1">
                    <p className="font-antonymMedium text-xs/[1]">
                      {t('page_course.author')}
                    </p>
                    <p className="pt-1 text-xl/[1] tracking-tighter">
                      {author.name}
                    </p>
                  </div>
                </Fragment>
              ))}
            </div>
            <div className="mt-4 flex h-full w-full flex-col items-start justify-between gap-4 border-t border-oiqDarkGray-900 py-4 font-antonymMedium">
              <div className="flex flex-wrap gap-0.5">
                {course.tags.map((tag) => (
                  <div
                    key={tag}
                    className="whitespace-nowrap rounded-full bg-oiqGray-200 px-3 text-xs/[2] text-oiqDarkGray-900 dark:bg-white"
                  >
                    {tag}
                  </div>
                ))}
              </div>
              <div
                className={`${
                  isAuthorized ? 'border-b' : ' border-t'
                } flex w-full flex-row items-end border-oiqDarkGray-900 py-4`}
              >
                <div
                  className="pl-2 font-antonymMedium text-xs/[0.9]"
                  dangerouslySetInnerHTML={{
                    __html: dp.sanitize(
                      t('page_course.abstractDurationDesktop')
                    ),
                  }}
                ></div>
                <div className="pl-2 font-formularNeutral text-lg/[0.9] tracking-tighter">
                  {duration}
                </div>
              </div>
            </div>
            {isAuthorized && (
              <EnrollToCourseButton
                courseId={course.id}
                courseState={course.state}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
