import { createStore } from 'effector'

export const $theme = createStore<'dark' | 'light'>('dark')

$theme.watch((theme) => {
  if (theme === 'dark') {
    document.documentElement.classList.add('dark')
  } else {
    document.documentElement.classList.remove('dark')
  }
})
