import { Footer } from 'widget/footer'
import { Header } from 'widget/header'

export default function AnalyticsPage() {
  return (
    <div>
      <Header />
      analytics
      <Footer />
    </div>
  )
}
