import { useTranslation } from 'react-i18next'
import { Maybe, User } from 'shared/gql'
import { profileRoute } from 'shared/routes'
import { Avatar, AvatarFallback, AvatarImage } from 'shared/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'shared/ui/dropdown-menu'
import { Icon } from 'shared/ui/icon'
import { signOutFx } from './model'

type Props = {
  viewer?: Maybe<Pick<User, 'id' | 'name' | 'avatarUrl'>>
}

export default function UserButton({ viewer }: Props) {
  const { t } = useTranslation()

  if (!viewer) return null

  const initials = viewer.name ? viewer.name[0] : '?'

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex items-center space-x-2 rounded-md p-1 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400">
        <Avatar>
          <AvatarImage src={viewer.avatarUrl} />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
        <div className="font-formularNeutral text-sm">{viewer.name}</div>
        <Icon
          name="entityViewer/DropDownIcon"
          className="h-3 w-3 stroke-current"
        />
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => profileRoute.open()}>
          {t('entity_viewer.profile')}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => signOutFx()}>
          {t('entity_viewer.logout')}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
