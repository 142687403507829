import { createStore, createEvent } from 'effector'

export const $open = createStore(false)
export const $content = createStore<JSX.Element | null>(null)

export const open = createEvent<JSX.Element>()
export const close = createEvent()

$open.on(open, () => true).on(close, () => false)
$content.on(open, (_, value) => value).on(close, () => null)

export const modal = {
  open,
  close,
}
