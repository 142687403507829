import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function CourseContentLoaderMobile({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 375 150"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="15" y="45" rx="5" ry="5" width="250" height="30" />
      <rect x="15" y="80" rx="14" ry="14" width="200" height="28" />
      <circle cx="346" cy="60" r="12" />
    </ContentLoader>
  )
}
