import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from 'shared/utils'

const inputVariants = cva(
  'h-12 w-full appearance-none rounded-md border text-xl/[1.1] -tracking-[0.01em] sm:text-base border-oiqDarkGray-900 bg-white pl-4 focus:outline-none focus:outline-transparent',
  {
    variants: {
      variant: {
        primary: '',
        subscribe:
          'sm:rounded-r-none sm:h-full sm:border-r-0 border-2 dark:bg-oiqDarkGray-900 dark:text-white',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  }
)

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, variant, ...props }, ref) => {
    return (
      <input
        className={cn(inputVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = 'Input'

export { Input, inputVariants }
