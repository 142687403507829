import { Route, RouterProvider } from 'atomic-router-react'
import { Analytics } from 'page/analytics'
import { Course } from 'page/course'
import { Courses } from 'page/courses'
import { Home } from 'page/home'
import { Lesson } from 'page/lesson'
import { PrivacyPolicy } from 'page/privacyPolicy'
import { Profile } from 'page/profile'
import 'shared/i18n'
import { Modal } from 'shared/modal'
import {
  analyticsRoute,
  courseRoute,
  coursesRoute,
  homeRoute,
  lessonRoute,
  privacyPolicyRoute,
  profileRoute,
} from 'shared/routes'
import { MobileMenu } from 'widget/mobileMenu'
import { router } from './routing'

export default function App() {
  return (
    <RouterProvider router={router}>
      <Route route={homeRoute} view={Home} />
      <Route route={coursesRoute} view={Courses} />
      <Route route={courseRoute} view={Course} />
      <Route route={lessonRoute} view={Lesson} />
      <Route route={profileRoute} view={Profile} />
      <Route route={analyticsRoute} view={Analytics} />
      <Route route={privacyPolicyRoute} view={PrivacyPolicy} />
      <MobileMenu />
      <Modal />
    </RouterProvider>
  )
}
