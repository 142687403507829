import { Link } from 'atomic-router-react'
import { Maybe, User } from 'shared/gql'
import { profileRoute } from 'shared/routes'
import { Avatar, AvatarFallback, AvatarImage } from 'shared/ui/avatar'
import { Icon } from 'shared/ui/icon'
import { signOutFx } from './model'

type Props = {
  viewer?: Maybe<Pick<User, 'id' | 'name' | 'avatarUrl'>>
}

export default function UserButtonMobile({ viewer }: Props) {
  if (!viewer) return null

  const initials = viewer.name ? viewer.name[0] : '?'

  return (
    <div className="flex w-full  flex-row items-center justify-between px-1 tracking-tight">
      <Link
        className="inline-flex items-center gap-2 rounded focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
        to={profileRoute}
      >
        <Avatar>
          <AvatarImage src={viewer.avatarUrl} />
          <AvatarFallback>{initials}</AvatarFallback>
        </Avatar>
        <div className="font-formularNeutral text-lg">{viewer.name}</div>
      </Link>
      <button
        className="rounded focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 sm:hidden dark:border-oiqDarkGray-800"
        onClick={() => signOutFx()}
      >
        <Icon
          className="h-[6vw] max-h-8 w-[6vw] max-w-8"
          name="widgetMobileMenu/arrowRight"
        />
      </button>
    </div>
  )
}
