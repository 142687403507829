import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function LessonMobileLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 375 300"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="20" y="25" rx="5" ry="5" width="320" height="20" />
      <rect x="20" y="50" rx="5" ry="5" width="200" height="20" />

      <rect x="20" y="90" rx="5" ry="5" width="330" height="12" />
      <rect x="20" y="107" rx="5" ry="5" width="300" height="12" />
      <rect x="20" y="124" rx="5" ry="5" width="330" height="12" />
      <rect x="20" y="141" rx="5" ry="5" width="300" height="12" />
      <rect x="20" y="157" rx="5" ry="5" width="330" height="12" />
      {/* <rect x="20" y="174" rx="5" ry="5" width="330" height="12" /> */}
      <rect x="20" y="191" rx="5" ry="5" width="330" height="12" />
      <rect x="20" y="208" rx="5" ry="5" width="300" height="12" />
      <rect x="20" y="225" rx="5" ry="5" width="330" height="12" />
      <rect x="20" y="242" rx="5" ry="5" width="300" height="12" />
      <rect x="20" y="259" rx="5" ry="5" width="330" height="12" />
    </ContentLoader>
  )
}
