import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function AsideContentLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 470 625"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="30" y="30" rx="5" ry="5" width="100" height="34" />
      <rect x="140" y="28" rx="19" ry="19" width="150" height="38" />
      <circle cx="414" cy="45" r="18" />

      <rect x="30" y="120" rx="5" ry="5" width="350" height="25" />
      <rect x="30" y="150" rx="5" ry="5" width="150" height="25" />
      <rect x="30" y="185" rx="13" ry="13" width="100" height="26" />
      <rect x="30" y="230" rx="5" ry="5" width="410" height="1" />

      <rect x="30" y="250" rx="5" ry="5" width="350" height="25" />
      <rect x="30" y="280" rx="5" ry="5" width="150" height="25" />
      <rect x="30" y="315" rx="13" ry="13" width="100" height="26" />
      <rect x="30" y="360" rx="5" ry="5" width="410" height="1" />

      <rect x="30" y="380" rx="5" ry="5" width="350" height="25" />
      <rect x="30" y="410" rx="5" ry="5" width="150" height="25" />
      <rect x="30" y="445" rx="13" ry="13" width="100" height="26" />
      <rect x="30" y="490" rx="5" ry="5" width="410" height="1" />

      <rect x="30" y="510" rx="5" ry="5" width="350" height="25" />
      <rect x="30" y="540" rx="5" ry="5" width="150" height="25" />
      <rect x="30" y="575" rx="13" ry="13" width="100" height="26" />
    </ContentLoader>
  )
}
