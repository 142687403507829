import { Dialog, Transition } from '@headlessui/react'
import { useUnit } from 'effector-react'
import { Fragment } from 'react'
import { $content, $open, close } from './model'

export default function Modal() {
  const open = useUnit($open)
  const content = useUnit($content)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => close()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-oiqDarkGray-800 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {content}
      </Dialog>
    </Transition.Root>
  )
}
