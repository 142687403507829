import { createStore } from 'effector'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

export const locales = [
  { value: 'ru', label: 'ру' },
  { value: 'ja', label: '日' },
  { value: 'en', label: 'en' },
]

export const $locale = createStore(locales[0])

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ru: {
        translation: {
          'entity_viewer.logout': 'выйти',
          'entity_viewer.profile': 'профиль',

          'feature_subscribeToNewsletter.title':
            'Мы в процессе. Скоро выложим <i>новые курсы,</i> библиотека постоянно пополняется. Ты залетел на огонёк вовремя, всё самое <i>интересное впереди!</i>',
          'feature_subscribeToNewsletter.policyLabel':
            'Я ознакомлен с политикой конфиденциальности и даю согласие на обработку моих персональных данных',
          'feature_subscribeToNewsletter.subscribeButton': 'подписаться',
          'feature_subscribeToNewsletter.success':
            'Ты успешно подписан на <i>новые курсы</i> и важные обновления!',
          'feature_subscribeToNewsletter.emailRequired':
            'Без почты не получится',
          'feature_subscribeToNewsletter.emailInvalid': 'Неверный формат почты',
          'feature_subscribeToNewsletter.policyAcceptRequired':
            'Без согласия не получится',

          'feature_themeToggle.toggleTheme': 'Переключить тему',
          'feature_themeToggle.themeToggler': 'переключатель темы',
          'feature_themeToggle.lightTheme': 'светлая тема',
          'feature_themeToggle.nightTheme': 'тёмная тема',

          'feature_enrollToCourse.buttonCourseNotStarted': 'начать курс',
          'feature_enrollToCourse.buttonCourseInProgress': 'продолжить',
          'feature_enrollToCourse.buttonCourseCompleted': 'посмотреть',

          'page_courses.title': 'Курсы и практика',
          'page_courses.noCoursesTitle':
            'Ничего не найдено&nbsp;<span>🕵️‍♂️</span>',
          'page_courses.noCoursesDescription':
            'По вашему запросу ничего не найдено. Попробуйте расширить фильтры и поисковый запрос.',
          'page_courses.part_count_one': '{{count}} часть',
          'page_courses.part_count_few': '{{count}} части',
          'page_courses.part_count_many': '{{count}} частей',
          'page_courses.lesson_count_one': '{{count}} урок',
          'page_courses.lesson_count_few': '{{count}} урока',
          'page_courses.lesson_count_many': '{{count}} уроков',
          'page_courses.variantCourse': 'курс',
          'page_courses.variantPractice': 'практика',
          'page_courses.variantSideQuest': 'квест',
          'page_courses.coming_soon': 'скоро!',

          'page_course.aboutTheCourse': 'о чём курс?',
          'page_course.author': 'автор курса',
          'page_course.howWeLearn': 'как учимся?',
          'page_course.duration_one': '{{count}} час',
          'page_course.duration_few': '{{count}} часа',
          'page_course.duration_many': '{{count}} часов',
          'page_course.durationHourOrLess': 'меньше часа',
          'page_course.lessonDuration_one': '{{count}} минута',
          'page_course.lessonDuration_few': '{{count}} минуты',
          'page_course.lessonDuration_many': '{{count}} минут',
          'page_course.abstractDuration': 'курс длится',
          'page_course.abstractDurationDesktop': 'курс <br/> длится',

          'page_home.goToCourses': 'смотреть курсы',
          'page_home.joinUs': 'хочешь также?<br />присоединяйся!',
          'page_home.happyCoding': 'программируем в радость вместе',
          'page_home.ringImageUrl':
            'https://media.0iq.tech/oiq-home-ring-ru.svg',
          'page_home.typescriptAndReact': `typescript и react <span class="text-oiqDarkGray-900 dark:text-white">//</span>
              <br />создали <span class="text-oiqLime-500">монстров</span> из нас`,

          'page_lesson.completed': 'пройден',
          'page_lesson.progress': 'прогресс',
          'page_lesson.lessonType': 'курс',
          'page_lesson.lessonMobileMenu': 'список уроков',
          'page_lesson.timing': 'мин',

          'page_privacyPolicy.privacyPolicy': `<article>
          <h1>Политика конфиденциальности для 0iq</h1>
          <p>Последнее обновление: 2024-01-26</p>
          <h2>1. Введение</h2>
          <p>
            Добро пожаловать в 0iq. Мы привержены уважению конфиденциальности
            наших пользователей. Эта Политика конфиденциальности объясняет,
            как мы собираем, используем и защищаем вашу информацию, когда вы
            используете наше приложение.
          </p>
          <h2>2. Собираемые данные</h2>
          <p>
            Мы собираем минимальное количество данных, необходимых для
            отслеживания вашего прогресса в обучении. Это включает: Ваш Google
            ID и/или GitHub ID, используемые для идентификации и
            аутентификации. Ваш адрес электронной почты, для управления
            аккаунтом и целей коммуникации.
          </p>
          <h2>3. Использование данных</h2>
          <p>
            Ваши данные используются исключительно в целях отслеживания и
            улучшения вашего опыта обучения в нашем приложении. Мы не делимся,
            не продаем, не сдаем в аренду и не обмениваем вашу информацию с
            третьими сторонами.
          </p>
          <h2>4. Безопасность</h2>
          <p>
            Мы серьезно относимся к безопасности данных и реализуем разумные
            меры для защиты вашей информации от несанкционированного доступа,
            раскрытия, изменения и уничтожения.
          </p>
          <h2>5. Изменения в этой Политике</h2>
          <p>
            Мы можем время от времени обновлять эту Политику
            конфиденциальности. Мы будем уведомлять вас о любых изменениях,
            обновляя дату в верхней части этой политики.
          </p>
          <h2>6. Свяжитесь с нами</h2>
          <p>
            Если у вас есть вопросы или опасения по поводу этой Политики
            конфиденциальности, пожалуйста, свяжитесь с нами по адресу 
            <a href="mailto:hello@0iq.tech">hello@0iq.tech</a>.
          </p>
        </article>`,

          'page_profile.greeting': `Привет,`,
          'page_profile.abstract':
            'Здесь курсы, которые ты проходишь и прогресс по ним. И запомни одну простую фразу: "Всё будет, но не сразу".',
          'page_profile.recommendations': 'Выбери что-нибудь и вперёд!',
          'page_profile.ctaGoToCourses': 'смотреть все курсы',
          'page_profile.coursesInProgress': 'Занятия в процессе',
          'page_profile.coursesCompleted': 'Пройденные занятия',
          'page_profile.buttonCourseCompleted': 'посмотреть',
          'page_profile.buttonCourseInProgress': 'продолжить',
          'page_profile.variantCourse': 'курс',
          'page_profile.progressBar': 'прогресс',

          'shared.intlDateTime': '{{val, datetime}}',

          'widget_authForm.title': 'Войдите в свою учетную запись',
          'widget_authForm.subtitle': 'чтобы перейти к 0iq',
          'widget_authForm.existingAccount': 'Уже есть аккаунт?',
          'widget_authForm.signIn': 'Войти',
          'widget_authForm.continueWithGithub': 'Продолжить с Github',
          'widget_authForm.continueWithGoogle': 'Продолжить с Google',
          'widget_authForm.forgotPassword': 'Забыл пароль',
          'widget_authForm.checkCorrection':
            'Проверьте правильность введенных данных',
          'widget_authForm.continue': 'продолжить',
          'widget_authForm.informedConsent':
            'Продолжая вход в 0iq, вы соглашаетесь с нашей',
          'widget_authForm.policy': 'Политикой конфиденциальности',

          'widget_footer.copyright': '© 2023-2024 0iq<br />Все права защищены',

          'widget_header.descriptor': 'Всё,<br/>что тебе<br/>нужно',
          'widget_header.navCourses': 'Курсы',
          'widget_header.navAnalytics': 'Аналитика',
          'widget_header.navProfile': 'Профиль',
          'widget_header.mobDescriptor': 'Всё, что<br/>тебе нужно',
          'widget_header.signIn': 'войти',
          'widget_header.signUp': 'регистрация',

          'widget_mobileMenu.navCourses': 'Курсы',
          'widget_mobileMenu.navAnalytics': 'Аналитика',
          'widget_mobileMenu.navProfile': 'Профиль',
          'widget_mobileMenu.signIn': 'войти',
          'widget_mobileMenu.signUp': 'регистрация',
        },
      },
      en: {
        translation: {
          'entity_viewer.logout': 'Log out',
          'entity_viewer.profile': 'Profile',

          'feature_subscribeToNewsletter.title':
            'We are in the process. We will soon release <i>new courses,</i> the library is constantly being updated. You`ve dropped in just in time, <i>all the most interesting stuff is yet to come!</i>',
          'feature_subscribeToNewsletter.policyLabel':
            'I have read and acknowledge the Privacy Policy and give my consent to the processing of my personal data',
          'feature_subscribeToNewsletter.subscribeButton': 'subscribe',
          'feature_subscribeToNewsletter.success':
            'You have successfully subscribed to <i>new courses</i> and important updates!',
          'feature_subscribeToNewsletter.emailRequired':
            'You must enter your e-mail',
          'feature_subscribeToNewsletter.emailInvalid': 'Wrong e-mail format',
          'feature_subscribeToNewsletter.policyAcceptRequired':
            'You must accept the Privacy Policy',

          'feature_themeToggle.toggleTheme': 'Switch theme',
          'feature_themeToggle.themeToggler': 'theme toggler',
          'feature_themeToggle.lightTheme': 'light theme',
          'feature_themeToggle.nightTheme': 'dark theme',

          'feature_enrollToCourse.buttonCourseNotStarted': 'start the course',
          'feature_enrollToCourse.buttonCourseInProgress': 'continue',
          'feature_enrollToCourse.buttonCourseCompleted': 'view',

          'page_courses.title': 'Courses and practices',
          'page_courses.noCoursesTitle': 'Nothing found&nbsp;<span>🕵️‍♂️</span>',
          'page_courses.noCoursesDescription':
            'Nothing was found according to your request. Try expanding your filters and search term.',
          'page_courses.part_count_one': '{{count}} part',
          'page_courses.part_count_few': '{{count}} parts',
          'page_courses.part_count_many': '{{count}} parts',
          'page_courses.lesson_count_one': '{{count}} lesson',
          'page_courses.lesson_count_few': '{{count}} lessons',
          'page_courses.lesson_count_many': '{{count}} lessons',
          'page_courses.variantCourse': 'course',
          'page_courses.variantPractice': 'practices',
          'page_courses.variantSideQuest': 'quest',
          'page_courses.coming_soon': 'coming soon!',

          'page_course.aboutTheCourse': 'what we study?',
          'page_course.author': 'course author',
          'page_course.howWeLearn': 'how we study?',
          'page_course.duration_one': '{{count}} hour',
          'page_course.duration_few': '{{count}} hours',
          'page_course.duration_many': '{{count}} hours',
          'page_course.durationHourOrLess': 'under an hour',
          'page_course.lessonDuration_one': '{{count}} minute',
          'page_course.lessonDuration_few': '{{count}} minutes',
          'page_course.lessonDuration_many': '{{count}} minutes',
          'page_course.abstractDuration': 'course lasts',
          'page_course.abstractDurationDesktop': 'course <br/> duration',

          'page_home.goToCourses': 'view courses',
          'page_home.joinUs': 'want the same?<br />join us!',
          'page_home.happyCoding': 'writing codes joyfully together',
          'page_home.ringImageUrl':
            'https://media.0iq.tech/oiq-home-ring-ru.svg',
          'page_home.typescriptAndReact': `typescript and react <span class="text-oiqDarkGray-900 dark:text-white">//</span>
              <br /> have turned us into  <span class="text-oiqLime-500">monsters</span>`,

          'page_lesson.completed': 'completed',
          'page_lesson.progress': 'progress',
          'page_lesson.lessonType': 'course',
          'page_lesson.lessonMobileMenu': 'List of lessons',
          'page_lesson.timing': 'min',

          'page_privacyPolicy.privacyPolicy': `<article>
          <h1>Privacy Policy for 0iq</h1>
          <p>Last Updated: 2024-01-26</p>
          <h2>1.Introduction</h2>
          <p>
            Welcome to 0iq. We are committed to respecting the privacy of our
            users. This Privacy Policy explains how we collect, use, and
            safeguard your information when you use our application.
          </p>
          <h2>2. Data Collected</h2>
          <p>
            We collect the minimum amount of data necessary for tracking your
            learning progress. This includes: Your Google ID and/or GitHub ID,
            used for identification and authentication. Your email address,
            for account management and communication purposes.
          </p>
          <h2>3. Use of Data</h2>
          <p>
            Your data is used solely for the purpose of tracking and improving
            your learning experience within our app. We do not share, sell,
            rent, or trade your information with third parties.
          </p>
          <h2>4. Security</h2>
          <p>
            We take data security seriously and implement reasonable measures
            to protect your information from unauthorized access, disclosure,
            alteration, and destruction.
          </p>
          <h2>5. Changes to This Policy</h2>
          <p>
            We may update this Privacy Policy from time to time. We will
            notify you of any changes by updating the date at the top of this
            policy.
          </p>
          <h2>6. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy,
            please contact us at 
            <a href="mailto:hello@0iq.tech">hello@0iq.tech</a>.
          </p>
        </article>`,

          'page_profile.greeting': `Hi,`,
          'page_profile.abstract':
            'Here are the courses you are taking and your progress in them. And keep in mind one simple phrase: "Slow and steady wins the race."',
          'page_profile.recommendations': 'Just pick up one and launch!',
          'page_profile.ctaGoToCourses': 'View all courses',
          'page_profile.coursesInProgress': 'Lessons in progress',
          'page_profile.coursesCompleted': 'Completed lessons',
          'page_profile.buttonCourseCompleted': 'view',
          'page_profile.buttonCourseInProgress': 'continue',
          'page_profile.variantCourse': 'course',
          'page_profile.progressBar': 'progress',

          'shared.intlDateTime': '{{val, datetime}}',

          'widget_authForm.title': 'Log in to your account',
          'widget_authForm.subtitle': 'to access 0iq',
          'widget_authForm.existingAccount': 'Already have an account?',
          'widget_authForm.signIn': 'Sign in',
          'widget_authForm.continueWithGithub': 'Continue with Github',
          'widget_authForm.continueWithGoogle': 'Continue with Google',
          'widget_authForm.forgotPassword': 'Forgot password',
          'widget_authForm.checkCorrection':
            'Check the accuracy of the entered data',
          'widget_authForm.continue': 'continue',
          'widget_authForm.informedConsent':
            'By signing into 0iq, you agree to our',
          'widget_authForm.policy': 'Privacy Policy',

          'widget_footer.copyright':
            '© 2023-2024 0iq<br />All rights reserved',

          'widget_header.descriptor': 'All<br/>you need',
          'widget_header.navCourses': 'Courses',
          'widget_header.navAnalytics': 'Analytics',
          'widget_header.navProfile': 'Profile',
          'widget_header.mobDescriptor': 'All<br/>you need',
          'widget_header.signIn': 'Sign in',
          'widget_header.signUp': 'Registration',

          'widget_mobileMenu.navCourses': 'Courses',
          'widget_mobileMenu.navAnalytics': 'Analytics',
          'widget_mobileMenu.navProfile': 'Profile',
          'widget_mobileMenu.signIn': 'Sign in',
          'widget_mobileMenu.signUp': 'Registration',
        },
      },
    },
  })

export default i18n
