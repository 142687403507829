import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function LessonVideoLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 1100 600"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      {/* <rect x="500" y="270" rx="10" ry="10" width="100" height="60" /> */}
      <polygon points="530,280 570,305 530,330" />
    </ContentLoader>
  )
}
