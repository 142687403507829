// import { api } from 'shared/api'
import { Button } from 'shared/ui/button'
// import { Input } from 'shared/input'
// import { modal } from 'shared/modal'
import { useTranslation } from 'react-i18next'
import { Icon } from 'shared/ui/icon'
import Container from './AuthFormContainer'
import { signInGithubFx, signInGoogleFx } from './model'
import { Link } from 'atomic-router-react'
import { privacyPolicyRoute } from 'shared/routes'
import { modal } from 'shared/modal'

export default function SignInForm() {
  const { t } = useTranslation()

  return (
    <Container
      title={t('widget_authForm.title')}
      subtitle={t('widget_authForm.subtitle')}
    >
      <>
        <div className="flex flex-col items-center justify-evenly gap-4 xxs:gap-5">
          <div className="flex w-full flex-col gap-3">
            <Button
              variant="ghost"
              className="inline-flex items-center gap-2 xxs:gap-3"
              onClick={() => signInGithubFx()}
            >
              <Icon name="widgetAuthForm/iconGithub" className="h-7 w-7" />
              {t('widget_authForm.continueWithGithub')}
            </Button>
            <Button
              variant="ghost"
              className="inline-flex items-center gap-2 xxs:gap-3"
              onClick={() => signInGoogleFx()}
            >
              <Icon name="widgetAuthForm/iconGoogle" className="h-7 w-7" />
              {t('widget_authForm.continueWithGoogle')}
            </Button>
            <div className="mt-2 text-sm">
              {t('widget_authForm.informedConsent')}{' '}
              <Link
                to={privacyPolicyRoute}
                className="underline"
                onClick={() => modal.close()}
              >
                {t('widget_authForm.policy')}
              </Link>
            </div>
          </div>
          {/* <Input
            variant="primary"
            className="dark:bg-white"
            placeholder="E-mail address"
          />
          <Button variant="primary" className="border-2">
            {t.continue}
          </Button> */}
          {/* <div className="flex flex-col text-lg/[1.5] tracking-tight">
            <div className="inline-flex gap-2">
              {t.existingAccount}
              <a href="" className="text-oiqLightGreen-400 underline">
                {t.signIn}
              </a>
            </div>
            <a href="" className="text-oiqLightGreen-400 underline">
              {t.forgotPassword}
            </a>
          </div> */}
        </div>
        {/* <div className="flex flex-row justify-end pb-2 pr-4 xxs:p-0 sm:hidden">
          <button
            type="button"
            className="flex items-center rounded-full focus-visible:outline-none focus-visible:outline-oiqLime-400 focus-visible:outline-offset-2"
            onClick={modal.close}
          >
            <Icon
              name="widgetAuthForm/arrowBack"
              className="h-[10vw] w-[10vw]"
            />
          </button>
        </div> */}
      </>
    </Container>
  )
}
