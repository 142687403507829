import { createRouteView } from 'atomic-router-react'
import { useUnit } from 'effector-react'
import { Footer } from 'widget/footer'
import { Header } from 'widget/header'
import { courseLoadedRoute, fetchCoursePage } from './model'
import CourseAbstract from './ui/CourseAbstract'
import CourseAbstractLoader from './ui/CourseAbstractLoader'
import CourseAbstractLoaderMobile from './ui/CourseAbstractLoaderMobile'
import CourseAbstractMobile from './ui/CourseAbstractMobile'
import CourseContentLoader from './ui/CourseContentLoader'
import CourseContentLoaderMobile from './ui/CourseContentLoaderMobile'
import CourseParts from './ui/CourseParts'

export default function CoursePage() {
  return (
    <div>
      <Header />
      <Course />
      <Footer />
    </div>
  )
}

const Course = createRouteView({
  // @ts-ignore
  route: courseLoadedRoute,
  view: () => {
    const { data } = useUnit(fetchCoursePage)
    const course = data?.course

    if (!course) return <CourseLoader />

    return (
      <>
        <div className="mx-auto mt-6 hidden w-11/12 max-w-6xl sm:block dark:text-oiqDarkGray-900">
          <CourseAbstract
            course={course}
            isAuthorized={Boolean(data.isAuthorized)}
          />
        </div>
        <div className="sm:hidden">
          <CourseAbstractMobile
            course={course}
            isAuthorized={Boolean(data.isAuthorized)}
          />
        </div>
        <CourseParts parts={course.parts} />
      </>
    )
  },
  otherwise: CourseLoader,
})

function CourseLoader() {
  return (
    <>
      <div className="mx-auto mt-6 hidden w-11/12 max-w-6xl sm:block dark:text-oiqDarkGray-900">
        <CourseAbstractLoader />
      </div>
      <div className="sm:hidden">
        <CourseAbstractLoaderMobile />
      </div>
      <CourseContentLoader className="mx-auto mt-2 hidden w-11/12 max-w-6xl rounded-lg bg-white sm:block dark:bg-oiqDarkGray-800 " />
      <CourseContentLoaderMobile className="mt-2 bg-white sm:hidden dark:bg-oiqDarkGray-800" />
    </>
  )
}
