import { createEvent } from 'effector'
import { $theme } from 'shared/theme'

export const toggleTheme = createEvent()

$theme.on(toggleTheme, (theme) => {
  if (theme === 'dark') {
    return 'light'
  } else {
    return 'dark'
  }
})
