import { Dialog, Transition } from '@headlessui/react'
import { Link } from 'atomic-router-react'
import { LanguageSwitch } from 'feature/languageSwitch'
import { useUnit } from 'effector-react'
import { UserButtonMobile } from 'entity/viewer'
import { ThemeButton } from 'feature/themeToggle'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { modal } from 'shared/modal'
import { analyticsRoute, coursesRoute } from 'shared/routes'
import { Icon } from 'shared/ui/icon'
import { SignInForm } from 'widget/authForm'
import { fetchMobileMenuWidget } from './model'

export default function MobileMenu() {
  const { t } = useTranslation()
  const { data } = useUnit(fetchMobileMenuWidget)

  const isAuthorized = Boolean(data?.viewer)

  const [isOpen, setIsOpen] = useState(false)
  function closeModal() {
    setIsOpen(false)
  }
  function openModal() {
    setIsOpen(true)
  }

  return (
    <>
      <div className="fixed inset-x-0 bottom-10 right-10 flex justify-end sm:hidden">
        <button
          className="rounded-full focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 sm:hidden dark:border-oiqDarkGray-800"
          onClick={openModal}
        >
          <Icon
            className="h-[10vw] max-h-14 w-[10vw] max-w-14"
            name="widgetMobileMenu/menu"
          />
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-x-0 bottom-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-end p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-oiqLightBlue-100 p-6 text-left font-formularNeutral text-oiqDarkGray-900 shadow-xl transition-all">
                  <div className="flex flex-row items-center justify-between gap-2">
                    <ThemeButton />
                    <LanguageSwitch />
                  </div>
                  <div className="inline-flex w-full items-center gap-3 py-6">
                    {/* <SignedIn>
                      <UserButton />
                    </SignedIn>
                    <SignedOut> */}
                    {isAuthorized && <UserButtonMobile viewer={data?.viewer} />}
                    {!isAuthorized && (
                      <>
                        <button
                          className="rounded p-0.5 font-formularNeutral uppercase text-oiqDarkGray-900 hover:text-oiqLime-500 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 dark:hover:text-oiqLime-500"
                          // className="min-w-8 hover:bg-chakraGray-100 active:bg-chakraGray-200 relative hidden h-8 w-auto items-center whitespace-nowrap rounded-md px-3 text-base font-semibold leading-extraTight transition duration-200 dark:hover:bg-white/[.08] dark:active:bg-white/[.16] xs:flex"
                          onClick={() => modal.open(<SignInForm />)}
                        >
                          {t('widget_mobileMenu.signIn')}
                        </button>
                      </>
                    )}
                    {/* <div className="font-formularNeutral text-oiqDarkGray-900 ">
                      |
                    </div>
                    <button
                      className="rounded p-0.5 font-formularNeutral uppercase text-oiqDarkGray-900 hover:text-oiqLime-500 focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 dark:hover:text-oiqLime-500"
                      // className="min-w-8 bg-chakraIndigo-500 hover:bg-chakraIndigo-600 active:bg-chakraIndigo-700 dark:bg-chakraIndigo-200 dark:text-chakraGray-800 dark:hover:bg-chakraIndigo-300 dark:active:bg-chakraIndigo-400 relative ml-2 inline-flex h-8 w-auto items-center whitespace-nowrap rounded-md px-3 text-base font-semibold leading-extraTight text-white transition duration-200"
                      // onClick={() => openSignUp()}
                    >
                      {t('widget_mobileMenu.signUp')}
                    </button> */}
                    {/* </SignedOut> */}
                  </div>
                  <div className="flex flex-col gap-1 text-xl">
                    <div className="border-t border-oiqLightGreen-200 py-2">
                      <Link
                        className="flex w-full flex-row items-center justify-between rounded-lg px-1 py-4 text-left uppercase tracking-tight focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 xs:py-6"
                        to={coursesRoute}
                      >
                        {t('widget_mobileMenu.navCourses')}
                        <Icon
                          className="h-[6vw] max-h-8 w-[6vw] max-w-8"
                          name="widgetMobileMenu/arrowRightSolid"
                        />
                      </Link>
                    </div>
                    {/* Analytics link */}
                    {data?.hasAnalytics && (
                      <div className="border-t border-oiqLightGreen-200 py-2">
                        <Link
                          className="flex w-full flex-row items-center justify-between rounded-lg px-1 py-4 text-left uppercase tracking-tight focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400 xs:py-6"
                          to={analyticsRoute}
                        >
                          {t('widget_mobileMenu.navAnalytics')}
                          <Icon
                            className="h-[6vw] max-h-8 w-[6vw] max-w-8"
                            name="widgetMobileMenu/arrowRight"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-row justify-end">
                    <button
                      type="button"
                      className="rounded-full focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
                      onClick={closeModal}
                    >
                      <Icon
                        className="h-[10vw] max-h-14 w-[10vw] max-w-14"
                        name="widgetMobileMenu/close"
                      />
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
