import { Button } from 'shared/ui/button'
import { useTranslation } from 'react-i18next'
import { enroll } from './model'
import { CourseState } from 'shared/gql'

type Props = {
  courseId: string
  courseState: CourseState
}

export default function EnrollToCourseButton({ courseId, courseState }: Props) {
  const { t } = useTranslation()

  let buttonLabel = ''
  switch (courseState) {
    case CourseState.NotStarted:
      buttonLabel = t('feature_enrollToCourse.buttonCourseNotStarted')
      break
    case CourseState.InProgress:
      buttonLabel = t('feature_enrollToCourse.buttonCourseInProgress')
      break
    case CourseState.Completed:
      buttonLabel = t('feature_enrollToCourse.buttonCourseCompleted')
      break
  }

  return (
    <div>
      <Button
        variant="primary"
        className="max-h-fit whitespace-normal break-words border focus-visible:outline-none focus-visible:outline-offset-2 focus-visible:outline-oiqLime-400"
        onClick={() => enroll(courseId)}
      >
        {buttonLabel}
      </Button>
    </div>
  )
}
