import { Link } from 'atomic-router-react'
import dp from 'dompurify'
import { useTranslation } from 'react-i18next'
import { coursesRoute } from 'shared/routes'

export default function WeAreCommunity() {
  const { t } = useTranslation()

  return (
    <section className="mt-10">
      <div className="flex flex-col justify-between gap-5 px-2 sm:flex-row sm:px-20" />
      <div className="relative mx-auto mt-10 flex flex-col items-center gap-24">
        <Link
          className="inline-flex h-12 cursor-pointer items-center justify-center font-formularNeutral text-3xl text-oiqWhite-50 duration-200 hover:underline sm:text-xl"
          to={coursesRoute}
        >
          {t('page_home.goToCourses')}
        </Link>
        <div className="flex flex-col-reverse sm:flex-row sm:items-center">
          <div className="sm:w-[70%]">
            <img
              src="https://imagedelivery.net/POP9xpSKQlswAeVv4Xp89Q/27d97d01-56c5-4e6e-79fa-767c6246e200/public"
              alt="Team welcome"
              className="px-2"
            />
            <svg
              className="absolute -bottom-[10%] left-[23%] -z-[1] stroke-[#83835F] dark:stroke-[#343434] sm:-bottom-[15%] sm:left-[20.5%] sm:mx-5 sm:w-7/12"
              viewBox="0 0 1037 1037"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="518.439" cy="518.495" r="143.58" />
              <circle cx="518.442" cy="518.495" r="187.032" />
              <circle cx="518.443" cy="518.495" r="295.611" />
              <circle cx="518.444" cy="518.495" r="517.944" />
            </svg>
          </div>
          <div className="flex flex-col gap-4 px-2 sm:-ml-12 md:-ml-16 lg:-ml-20">
            <div
              className="font-antonymMedium text-3xl/[1] tracking-tight text-white [text-shadow:_0_2.7px_5px_#76765B] sm:text-xl/[1] lg:text-3xl/[1]"
              dangerouslySetInnerHTML={{
                __html: dp.sanitize(t('page_home.typescriptAndReact')),
              }}
            />
            <div
              className="mb-12 ml-8 mt-6 text-2xl/[.9] tracking-tighter sm:text-lg/[.9] lg:text-2xl/[.9]"
              dangerouslySetInnerHTML={{
                __html: dp.sanitize(t('page_home.joinUs')),
              }}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
