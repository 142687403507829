import { useUnit } from 'effector-react'
import ContentLoader from 'react-content-loader'
import { $theme } from 'shared/theme'

type Props = {
  className?: string
}

export default function LessonContentLoader({ className }: Props) {
  const theme = useUnit($theme)
  const backgroundColor = theme === 'dark' ? '#202020' : '#C6D2DD'
  const foregroundColor = theme === 'dark' ? '#646464' : '#F6F9FC'

  return (
    <ContentLoader
      className={className}
      speed={2}
      viewBox="0 0 1100 300"
      backgroundColor={backgroundColor}
      foregroundColor={foregroundColor}
    >
      <rect x="70" y="50" rx="5" ry="5" width="900" height="35" />

      <rect x="70" y="120" rx="5" ry="5" width="960" height="20" />
      <rect x="70" y="145" rx="5" ry="5" width="900" height="20" />
      <rect x="70" y="170" rx="5" ry="5" width="800" height="20" />
      {/* <rect x="70" y="195" rx="5" ry="5" width="800" height="20" /> */}
      <rect x="70" y="220" rx="5" ry="5" width="960" height="20" />
      <rect x="70" y="245" rx="5" ry="5" width="860" height="20" />
    </ContentLoader>
  )
}
